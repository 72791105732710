import HttpService from './http.service';

export default {
  getAll,
  getById,
  deleteWebhook,
  createWebhook,
  updateWebhook,
  fetchWebhookEvents,
  resendWebhookEvent,
  deleteWebhookEvent,
};

async function getAll() {
  return HttpService.get('/webhooks');
}

async function getById(id) {
  return HttpService.get(`/webhooks/${id}`);
}

async function deleteWebhook(id) {
  return HttpService.delete(`/webhooks/${id}`);
}

async function createWebhook(values) {
  return HttpService.post('/webhooks', values);
}

async function updateWebhook(id, values) {
  return HttpService.put(`/webhooks/${id}`, values);
}

async function fetchWebhookEvents(id, params) {
  return HttpService.get(`/webhook-events/${id}`, params);
}

async function resendWebhookEvent(id) {
  return HttpService.post(`/webhook-resend-event/${id}`);
}

async function deleteWebhookEvent(id) {
  return HttpService.delete(`/webhook-ignore-event/${id}`);
}