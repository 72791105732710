import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';

import { StyledPagination } from './styles';

function CustomPagination({
  total,
  itemsPerPage,
  currentPage,
  onPageChange,
  className,
  size,
}) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage));
    }

    if (total === 0) {
      setTotalPages(1);
    }
  }, [total, itemsPerPage]);

  const dotItem = useMemo(() => <Pagination.Item disabled>...</Pagination.Item>, []);

  const items = useMemo(() => {
    const pages = [];

    let startPage = currentPage < 5 ? 1 : currentPage - 4;
    let endPage = 4 + startPage;
    endPage = totalPages < endPage ? totalPages : endPage;
    const diff = startPage - endPage + 4;
    startPage -= startPage - diff > 0 ? diff : 0;

    if (startPage > 1) {
      pages.push(
        <Pagination.Item
          key={1}
          active={currentPage === 1}
          onClick={() => onPageChange(1)}
        >
          1
        </Pagination.Item>,
      );
      pages.push(dotItem);
    }

    for (let index = startPage; index <= endPage; index++) {
      pages.push(
        <Pagination.Item
          key={index}
          active={index === currentPage}
          onClick={() => onPageChange(index)}
        >
          {index}
        </Pagination.Item>,
      );
    }

    if (endPage < totalPages) {
      pages.push(dotItem);
      pages.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>,
      );
    }

    return pages;
  }, [totalPages, dotItem, currentPage, onPageChange]);

  if (totalPages === 0) return null;

  return (
    <StyledPagination size={size} className={className} disabled>
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {items}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </StyledPagination>
  );
}

CustomPagination.defaultProps = {
  total: 0,
  itemsPerPage: 10,
  currentPage: 1,
  className: '',
  size: 'md',
};

CustomPagination.propTypes = {
  total: PropTypes.number,
  className: PropTypes.string,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default CustomPagination;
