import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import Profile from './Profile';
import authActionCreators from '_store/_actions/auth';

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  user: state.user.user,
  authUser: state.auth.user,
});

const mapDispatchToProps = {
  onFetchUser: userActionCreators.getUserProfile,
  onUpdateUser: userActionCreators.updateUserProfile,
  onAvatarUpload: userActionCreators.uploadAvatar,
  onAvatarDelete: userActionCreators.deleteAvatar,
  onMigrateUser: userActionCreators.migrateUser,
  onUndoMigrateUser: userActionCreators.undoMigrateUser,
  onLogout: authActionCreators.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
