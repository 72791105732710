import { connect } from 'react-redux';

import webhooksActionCreators from '_store/_actions/webhooks';

import WebhookEvents from './WebhookEvents';

const mapStateToProps = state => ({
  isLoading: state.webhooks.isLoading,
  events: state.webhooks.events,
  eventsTotal: state.webhooks.eventsTotal,
});

const mapDispatchToProps = {
  onFetchWebhookEvents: webhooksActionCreators.fetchWebhookEvents,
  onResendWebhookEvent: webhooksActionCreators.resendWebhookEvent,
  onDeleteWebhookEvent: webhooksActionCreators.deleteWebhookEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookEvents);
