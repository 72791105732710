import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Col, Row } from 'react-bootstrap';
import { LuArrowRight } from 'react-icons/lu';
import { FaExclamationCircle } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery, getSubscriptionMaxUsers } from 'helpers';
import { Button, HintIcon, Select, SimpleAlert } from '_components/_core';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';
import { Overview } from './styles';

pluralize.addIrregularRule('adicional', 'adicionais');

function AdditionalCompanies({
  subscription,
  companySeatsPricing,
  onChangeView,
  onFetchCompanySeatsPricing,
  onConfirmCompanySeatsPurchase,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfCompanies, setNumberOfCompanies] = useState(0);

  const { isMobile, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge } =
    useMediaQuery();

  useEffect(() => {
    onFetchCompanySeatsPricing();
  }, [onFetchCompanySeatsPricing]);

  const columnSize = useMemo(() => {
    if (isDesktopMedium) {
      return 12;
    }

    if (isDesktopLarge) {
      return 8;
    }

    if (isDesktopExtraLarge) {
      return 6;
    }

    return 12;
  }, [isDesktopMedium, isDesktopLarge, isDesktopExtraLarge]);

  const totalPrice = useMemo(() => {
    const { product_info } = companySeatsPricing || {};
    const { price } = product_info || {};

    return price * numberOfCompanies;
  }, [companySeatsPricing, numberOfCompanies]);

  const maxUsers = useMemo(() => {
    return getSubscriptionMaxUsers(subscription);
  }, [subscription]);

  const handleNumberOfUsersChange = option => {
    const value = Number(option.value);

    if (value < 0 || value > 10) {
      return;
    }

    setNumberOfCompanies(value);
  };

  const companiesOptions = useMemo(() => {
    const { product_info } = companySeatsPricing || {};
    const { price } = product_info || {};

    return Array.from({ length: 10 }).map((_, index) => {
      let totalPrice = FORMATTERS.NUMBER(price * (index + 1));

      totalPrice += ` ${subscription.plan.cycle}`;

      return {
        value: index + 1,
        label: `${index + 1} ${pluralize('empresa', index + 1)} ${pluralize('adicional', index + 1)} (${totalPrice})`,
      };
    });
  }, [companySeatsPricing, subscription]);

  const newSubscriptionPrice = useMemo(() => {
    return FORMATTERS.NUMBER(subscription.total_amount + totalPrice);
  }, [subscription, totalPrice]);

  const newSubscriptionPriceText = useMemo(() => {
    return `${newSubscriptionPrice} ${subscription.plan.cycle}`;
  }, [subscription, newSubscriptionPrice]);

  const newPriceText = useMemo(() => {
    return (
      <h6 className="d-flex justify-content-center align-items-center m-0 p-0">
        <s style={{ color: '#a9afb3' }}>{FORMATTERS.NUMBER(subscription.total_amount)}</s>
        <LuArrowRight className="ml-2 mr-2" />
        {newSubscriptionPriceText}
      </h6>
    );
  }, [subscription, newSubscriptionPriceText]);

  const newSeatsText = useMemo(() => {
    return (
      <small className="d-flex justify-content-center align-items-center">
        <s style={{ color: '#a9afb3' }}>{maxUsers.total} lugares</s>
        <LuArrowRight className="ml-2 mr-2" />
        {`${maxUsers.total + numberOfCompanies} lugares`}
      </small>
    );
  }, [maxUsers, numberOfCompanies]);

  const amountDueToday = useMemo(() => {
    const { subscription_info } = companySeatsPricing || {};
    const { due_per_company } = subscription_info || {};

    const amountDue = due_per_company * numberOfCompanies;

    return amountDue;
  }, [companySeatsPricing, numberOfCompanies]);

  const amountDueTodayText = useMemo(() => {
    return (
      <small className="d-flex justify-content-center align-items-center text-warning">
        {`Valor devido hoje: ${FORMATTERS.NUMBER(amountDueToday)}`}
      </small>
    );
  }, [amountDueToday]);

  const amountDueNextText = useMemo(() => {
    return (
      <small className="d-flex justify-content-center align-items-center text-info">
        O valor adicional será incluído na fatura em aberto.
      </small>
    );
  }, []);

  const newPriceHint = useMemo(() => {
    const { subscription_info } = companySeatsPricing || {};
    const { days_left } = subscription_info || {};

    const daysLeftText = days_left < 0 ? '' : ` (Renova em ${days_left} dias)`;

    return `O novo valor total da assinatura após a contratação de novas empresas.${daysLeftText}`;
  }, [companySeatsPricing]);

  const newSeatsHint = useMemo(() => {
    return 'Quantidade total de lugares que ficarão disponíveis após o pagamento.';
  }, []);

  const amountDueTodayHint = useMemo(() => {
    return 'Dias restantes na assinatura atual multiplicado pelo valor diário das novas empresas.';
  }, []);

  const renderConfirmation = useCallback(() => {
    if (numberOfCompanies === 0) {
      return null;
    }

    if (isMobile) {
      return (
        <>
          <Overview className="mt-3 bg-white">
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-between" style={{ flex: 1 }}>
                <div className="d-flex flex-column align-items-start">
                  <h4 className="d-flex justify-content-center align-items-center mb-3">
                    Confirmar alteração na assinatura
                  </h4>
                  {newPriceText}
                  {newSeatsText}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <FaExclamationCircle size="16" className="text-yellow" />
                </div>
              </div>
            </div>
          </Overview>
          <small className="d-flex justify-content-between align-items-center text-muted mt-3">
            <i>
              A quantidade de empresas será atualizada após a confirmação do pagamento.
            </i>
          </small>
        </>
      );
    }

    return (
      <Overview className="mt-3 bg-white">
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <FaExclamationCircle size="16" className="text-yellow" />
          </div>
          <div
            className="d-flex justify-content-between align-items-center ml-3"
            style={{ flex: 1 }}
          >
            <div className="d-flex flex-column">
              <div>
                <h4 className="d-flex justify-content-start align-items-center">
                  Confirmar alteração na assinatura
                </h4>
              </div>
              <div>
                <small className="d-flex justify-content-start align-items-center text-muted">
                  <i>
                    A quantidade de usuários será atualizada após a confirmação do
                    pagamento.
                  </i>
                </small>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                {newPriceText}{' '}
                <HintIcon
                  className="ml-2 d-flex align-items-center justify-content-center"
                  hint={newPriceHint}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                {newSeatsText}{' '}
                <HintIcon
                  className="ml-2 d-flex align-items-center justify-content-center"
                  hint={newSeatsHint}
                />
              </div>
              {amountDueToday > 0 && (
                <div className="d-flex align-items-center justify-content-center mt-2">
                  {amountDueTodayText}{' '}
                  <HintIcon
                    className="ml-2 d-flex align-items-center justify-content-center"
                    hint={amountDueTodayHint}
                  />
                </div>
              )}
              {amountDueToday <= 0 && (
                <div className="d-flex align-items-center justify-content-center mt-2">
                  {amountDueNextText}
                  <HintIcon
                    className="ml-2 d-flex align-items-center justify-content-center"
                    hint={amountDueTodayHint}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Overview>
    );
  }, [
    isMobile,
    numberOfCompanies,
    newPriceText,
    newSeatsText,
    amountDueTodayText,
    amountDueTodayHint,
    newPriceHint,
    newSeatsHint,
    amountDueNextText,
    amountDueToday,
  ]);

  const handleConfirm = useCallback(() => {
    if (!companySeatsPricing) {
      return;
    }

    setIsLoading(true);

    const params = {
      product_id: companySeatsPricing.product_info.id,
      quantity: numberOfCompanies,
    };

    const successCallback = data => {
      setIsLoading(false);

      if (data.provider_invoice_url) {
        window.location.assign(data.provider_invoice_url);
      }
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    onConfirmCompanySeatsPurchase(params, successCallback, errorCallback);
  }, [onConfirmCompanySeatsPurchase, numberOfCompanies, companySeatsPricing]);

  if (isEmpty(companySeatsPricing)) {
    return null;
  }

  if (!companySeatsPricing.canPurchase) {
    return (
      <>
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Empresas adicionais</CustomBreadcrumbItem>
        </CustomBreadcrumb>
        <Row>
          <Col>
            <SimpleAlert variant="danger">
              <h5>Não é possível adquirir empresas adicionais.</h5>
              <p className="m-0 p-0">
                <span>{companySeatsPricing.message}</span>
              </p>
            </SimpleAlert>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Empresas adicionais</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col xs={columnSize}>
          <CustomCard>
            <CustomCardHeader>
              <h3>Adquirir empresas adicionais</h3>
            </CustomCardHeader>
            <CustomCardBody className="d-flex justify-content-start align-items-center">
              <Select
                value={companiesOptions.find(
                  option => option.value === numberOfCompanies,
                )}
                onChange={handleNumberOfUsersChange}
                options={companiesOptions}
                isSearchable={false}
                className={isMobile ? 'w-100' : 'w-50'}
              />
            </CustomCardBody>
          </CustomCard>
          {renderConfirmation()}
          {numberOfCompanies > 0 && (
            <Button
              variant="success-2"
              className="d-flex justify-content-center align-items-center float-right mt-3"
              onClick={handleConfirm}
              disabled={numberOfCompanies === 0 || numberOfCompanies > 10}
              isLoading={isLoading}
            >
              {amountDueToday > 0
                ? `Confirmar e pagar ${FORMATTERS.NUMBER(amountDueToday)}`
                : 'Confirmar alteração'}
              <LuArrowRight size="16" className="ml-2" />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

AdditionalCompanies.defaultProps = {
  activeCompany: {},
};

AdditionalCompanies.propTypes = {
  onFetchCompanySeatsPricing: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  companySeatsPricing: PropTypes.object,
};

export default AdditionalCompanies;
