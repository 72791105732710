import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import RepeatInvoiceChargeModal from './RepeatInvoiceChargeModal';

const mapStateToProps = state => ({
  paymentMethods: state.subscription.paymentMethods,
  subscription: state.subscription.companySubscription,
});

const mapDispatchToProps = {
  onFetchPaymentMethods: subscriptionActionCreators.fetchPaymentMethods,
  onFetchSubscription: subscriptionActionCreators.getCompanySubscription,
  onPayInvoice: subscriptionActionCreators.payInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatInvoiceChargeModal);
