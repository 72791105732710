import { integrationsConstants } from '_store/_constants';

const INITIAL_STATE = {
  isLoading: false,
  bankIntegrationSettings: [],
};

export function integrations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case integrationsConstants.FETCH_BANK_INTEGRATION_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case integrationsConstants.FETCH_BANK_INTEGRATION_SETTINGS_SUCCESS: {
      const { response } = action.payload;

      return {
        ...state,
        bankIntegrationSettings: response.data,
        isLoading: false,
      };
    }

    case integrationsConstants.FETCH_BANK_INTEGRATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case integrationsConstants.ADD_BANK_INTEGRATION_SETTINGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case integrationsConstants.ADD_BANK_INTEGRATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case integrationsConstants.ADD_BANK_INTEGRATION_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case integrationsConstants.DELETE_INTEGRATION_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case integrationsConstants.DELETE_INTEGRATION_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case integrationsConstants.DELETE_INTEGRATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case integrationsConstants.UPDATE_INTEGRATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case integrationsConstants.UPDATE_INTEGRATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case integrationsConstants.UPDATE_INTEGRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
