import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, ButtonGroup, Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import { Button } from '_components/_core';
import { Plan } from '_components/_shared';

import { PlansContainer, StyledCycleButton } from '../styles';

function SelectPlan({ plans, subscription, onChoosePlan, onBackToHome }) {
  const [billingCycle, setBillingCycle] = useState(1);
  const [mnemonicShort, setMnemonicShort] = useState('EMPRESARIAL');
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  const isFounderPlansOnly = useMemo(() => {
    return plans.every(plan => plan.type === 'FOUNDER_PLAN');
  }, [plans]);

  const validPlans = useMemo(() => {
    if (isFounderPlansOnly) {
      return sortBy(
        plans.filter(plan => plan.mnemonic_short === mnemonicShort),
        'price',
      );
    }

    return sortBy(
      plans.filter(plan => plan.cycle_months === billingCycle),
      'price',
    );
  }, [plans, billingCycle, isFounderPlansOnly, mnemonicShort]);

  const mnemonics = useMemo(() => {
    return plans.reduce((acc, plan) => {
      if (!acc.includes(plan.mnemonic_short)) {
        acc.push(plan.mnemonic_short);
      }

      return acc;
    }, []);
  }, [plans]);

  const renderBackButton = useCallback(() => {
    if (!subscription || subscription.status === 'trialing') {
      return null;
    }

    return (
      <Button
        variant="link"
        className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
        onClick={onBackToHome}
      >
        <IoArrowBack className="mr-1" />
        Voltar
      </Button>
    );
  }, [subscription, onBackToHome]);

  return (
    <>
      <div className="mt-3">
        <div className="mt-3 d-flex align-items-center">{renderBackButton()}</div>
        <h4 className="m-0 d-flex justify-content-center align-items-center">
          Assine um plano para manter sua empresa organizada
        </h4>
      </div>
      <div className="mt-3 mb-3 d-flex justify-content-center align-items-center">
        {isFounderPlansOnly && (
          <ButtonGroup
            className={classNames({
              'ml-auto mr-auto': true,
              'd-none': mnemonics.length === 1,
            })}
          >
            {mnemonics.map(mnemonic => (
              <StyledCycleButton
                variant="default"
                onClick={() => setMnemonicShort(mnemonic)}
                active={mnemonicShort === mnemonic}
              >
                {mnemonic.toLowerCase().toUpperCase()}
              </StyledCycleButton>
            ))}
          </ButtonGroup>
        )}
        {!isFounderPlansOnly && (
          <ButtonGroup className="ml-auto mr-auto mb-3">
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(1)}
              active={billingCycle === 1}
            >
              Mensal
            </StyledCycleButton>
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(6)}
              active={billingCycle === 6}
            >
              Semestral
              <Badge variant="success" className="ml-2">
                <span>até 12% OFF</span>
              </Badge>
            </StyledCycleButton>
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(12)}
              active={billingCycle === 12}
            >
              Anual
              <Badge variant="success" className="ml-2">
                <span>até 16% OFF</span>
              </Badge>
            </StyledCycleButton>
          </ButtonGroup>
        )}
      </div>
      <Row className="d-flex align-items-center">
        <Col md={{ span: 12, offset: 0 }}>
          <PlansContainer>
            {validPlans.map((plan, index) => (
              <Plan
                key={plan.id}
                plan={plan}
                isActive={selectedPlanIndex === index}
                onSelectPlan={() => setSelectedPlanIndex(index)}
                onChoosePlan={() => onChoosePlan(plan.id)}
                allPlans={plans}
              />
            ))}
          </PlansContainer>
        </Col>
      </Row>
      {billingCycle !== 1 && (
        <Row className="mt-3">
          <Col className="text-center">
            <small>A economia é calculada com base no plano mensal.</small>
          </Col>
        </Row>
      )}
    </>
  );
}

SelectPlan.propTypes = {
  plans: PropTypes.array.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  onBackToHome: PropTypes.func.isRequired,
  subscription: PropTypes.object,
};

export default SelectPlan;
