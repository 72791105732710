import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  SubscriptionActionsCard,
  SubscriptionAmountCard,
  SubscriptionInformationCard,
  SubscriptionRenewalDateCard,
} from './components';
import PaymentAlert from '../PaymentAlert/PaymentAlertContainer';

function Overview({ subscription, onChangeView, onGetUpdatePaymentSessionUrl }) {
  if (!subscription) {
    return null;
  }

  return (
    <>
      <PaymentAlert />
      <h4 className="mt-3 mb-3">Assinatura</h4>
      <Row>
        <Col md={12}>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <SubscriptionAmountCard subscription={subscription} />
            </Col>
            <Col className="mt-3 mt-lg-0" lg={6} md={12} sm={12}>
              <SubscriptionRenewalDateCard
                subscription={subscription}
                onChangeView={onChangeView}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={6} sm={12}>
              <SubscriptionInformationCard
                subscription={subscription}
                onChangeView={onChangeView}
                onGetUpdatePaymentSessionUrl={onGetUpdatePaymentSessionUrl}
              />
            </Col>
            <Col lg={6} sm={12}>
              <SubscriptionActionsCard
                subscription={subscription}
                onChangeView={onChangeView}
                onGetUpdatePaymentSessionUrl={onGetUpdatePaymentSessionUrl}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

Overview.defaultProps = {
  subscription: null,
};

Overview.propTypes = {
  onChangeView: PropTypes.func,
  subscription: PropTypes.object,
};

export default Overview;
