import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from 'helpers';

import {
  StyledWrapper,
  StyledAvatar,
  StyledUserName,
  StyledUserRole,
  StyledMenuText,
  StyledOverlayTrigger,
  StyledPopover,
  MenuEntry,
  StyledLuBuilding2,
  StyledIoLogOutOutline,
  StyledLuBell,
  StyledLuUser,
  AvatarWrapper,
  StyledDiv,
} from './styles';

function AvatarMenuV2({ user, onLogout, onFetchUser }) {
  const history = useHistory();

  const { isMobile, isTablet } = useMediaQuery();

  useEffect(() => {
    onFetchUser();
  }, [onFetchUser]);

  const isMobileOrTablet = isMobile || isTablet;

  const name = useMemo(() => {
    if (user) {
      const { document_type, first_name, last_name } = user;

      if (document_type === 'PJ') {
        return `${first_name}`;
      }

      return `${first_name} ${last_name}`;
    }

    return '';
  }, [user]);

  const avatar_url = useMemo(() => {
    if (user && user.avatar_url) {
      return user.avatar_url;
    }

    return 'assets/img/default-avatar.svg';
  }, [user]);

  const popover = useCallback(
    () => (
      <StyledPopover>
        <Popover.Content>
          <MenuEntry
            className="row"
            onClick={() => {
              history.push('/configuracoes/dados-pessoais');
              document.body.click();
            }}
          >
            <StyledLuUser size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
            <StyledMenuText>Meu perfil</StyledMenuText>
          </MenuEntry>
          <MenuEntry
            className="row"
            onClick={() => {
              history.push('/configuracoes/preferencias');
              document.body.click();
            }}
          >
            <StyledLuBell size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
            <StyledMenuText>Preferências</StyledMenuText>
          </MenuEntry>
          <MenuEntry
            className="row"
            onClick={() => {
              history.push('/configuracoes/empresas');
              document.body.click();
            }}
          >
            <StyledLuBuilding2 size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
            <StyledMenuText>Minhas empresas</StyledMenuText>
          </MenuEntry>
          <hr
            style={{
              padding: 0,
              margin: 0,
            }}
          />
          <MenuEntry className="row" onClick={onLogout}>
            <StyledIoLogOutOutline size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
            <StyledMenuText className="text-muted">Sair do sistema</StyledMenuText>
          </MenuEntry>
        </Popover.Content>
      </StyledPopover>
    ),
    [history, onLogout],
  );

  return (
    <StyledOverlayTrigger
      trigger="click"
      placement={isMobileOrTablet ? 'bottom-end' : 'bottom'}
      overlay={popover()}
      rootClose
    >
      <StyledWrapper>
        <StyledDiv className="row">
          <AvatarWrapper>
            <StyledAvatar src={avatar_url} alt="user-avatar" />
          </AvatarWrapper>
          {!isMobileOrTablet && (
            <div>
              <StyledUserName>{name}</StyledUserName>
              <StyledUserRole>{user.email}</StyledUserRole>
            </div>
          )}
        </StyledDiv>
      </StyledWrapper>
    </StyledOverlayTrigger>
  );
}

AvatarMenuV2.defaultProps = {
  user: null,
};

AvatarMenuV2.propTypes = {
  user: PropTypes.object,
  onFetchUser: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
};

export default AvatarMenuV2;
