import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import ChangePaymentMethod from './ChangePaymentMethod';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  isLoading: state.subscription.isLoading,
  paymentMethods: state.subscription.paymentMethods,
});

const mapDispatchToProps = {
  onGetUpdatePaymentSessionUrl: subscriptionActionCreators.getUpdatePaymentSessionUrl,
  onFetchPaymentMethods: subscriptionActionCreators.fetchPaymentMethods,
  onSetDefaultPaymentMethod: subscriptionActionCreators.setDefaultPaymentMethod,
  onDeletePaymentMethod: subscriptionActionCreators.deletePaymentMethod,
  onCreatePaymentMethod: subscriptionActionCreators.createPaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePaymentMethod);
