export const webhooksConstants = {
  WEBHOOKS_GET_ALL_REQUEST: 'WEBHOOKS_GET_ALL_REQUEST',
  WEBHOOKS_GET_ALL_SUCCESS: 'WEBHOOKS_GET_ALL_SUCCESS',
  WEBHOOKS_GET_ALL_FAILURE: 'WEBHOOKS_GET_ALL_FAILURE',

  WEBHOOKS_DELETE_REQUEST: 'WEBHOOKS_DELETE_REQUEST',
  WEBHOOKS_DELETE_SUCCESS: 'WEBHOOKS_DELETE_SUCCESS',
  WEBHOOKS_DELETE_FAILURE: 'WEBHOOKS_DELETE_FAILURE',

  WEBHOOKS_CREATE_REQUEST: 'WEBHOOKS_CREATE_REQUEST',
  WEBHOOKS_CREATE_SUCCESS: 'WEBHOOKS_CREATE_SUCCESS',
  WEBHOOKS_CREATE_FAILURE: 'WEBHOOKS_CREATE_FAILURE',

  WEBHOOKS_UPDATE_REQUEST: 'WEBHOOKS_UPDATE_REQUEST',
  WEBHOOKS_UPDATE_SUCCESS: 'WEBHOOKS_UPDATE_SUCCESS',
  WEBHOOKS_UPDATE_FAILURE: 'WEBHOOKS_UPDATE_FAILURE',

  FETCH_WEBHOOK_EVENTS_REQUEST: 'FETCH_WEBHOOK_EVENTS_REQUEST',
  FETCH_WEBHOOK_EVENTS_SUCCESS: 'FETCH_WEBHOOK_EVENTS_SUCCESS',
  FETCH_WEBHOOK_EVENTS_FAILURE: 'FETCH_WEBHOOK_EVENTS_FAILURE',

  RESEND_WEBHOOK_EVENT_REQUEST: 'RESEND_WEBHOOK_EVENT_REQUEST',
  RESEND_WEBHOOK_EVENT_SUCCESS: 'RESEND_WEBHOOK_EVENT_SUCCESS',
  RESEND_WEBHOOK_EVENT_FAILURE: 'RESEND_WEBHOOK_EVENT_FAILURE',

  DELETE_WEBHOOK_EVENT_REQUEST: 'DELETE_WEBHOOK_EVENT_REQUEST',
  DELETE_WEBHOOK_EVENT_SUCCESS: 'DELETE_WEBHOOK_EVENT_SUCCESS',
  DELETE_WEBHOOK_EVENT_FAILURE: 'DELETE_WEBHOOK_EVENT_FAILURE',
};

export default webhooksConstants;
