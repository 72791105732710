import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import ChangePlan from './ChangePlan';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  plans: state.subscription.subscriptionPlans,
});

const mapDispatchToProps = {
  onFetchPlans: subscriptionActionCreators.getCompanySubscriptionPlans,
  onValidatePlanChange: subscriptionActionCreators.validatePlanChange,
  onConfirmPlanChange: subscriptionActionCreators.confirmPlanChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlan);
