import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: inline-flex;
  padding: 5px 16px;
  border-color: transparent;
  font-weight: 500;
  font-size: 12px;
  height: auto;
  white-space: nowrap;
  background: #fafafa;
  opacity: 1;
  transition: all .3s;
  border-radius: 0.25em;

  ${({ variant }) => variant === 'default' && css`
    color: #455560;
    background: #E8EAF3;
  `}

  ${({ variant }) => variant === 'primary' && css`
    color: #2a59d1;
    background: rgba(62,121,247,.1);
  `}

  ${({ variant }) => variant === 'success' && css`
    color: #00ab6f;
    background: rgba(4,209,130,.1);
  `}

  ${({ variant }) => variant === 'warning' && css`
    color: #d46b08;
    background: rgba(250,140,22,.1);
  `}

  ${({ variant }) => variant === 'danger' && css`
    color: #b82a23;
    background: rgba(222,68,54,.1);
  `}

  ${({ variant }) => variant === 'info' && css`
    color: #0997d9;
    background: rgba(23,188,255,.1);
  `}

  ${({ variant }) => variant === 'payment_plan' && css`
    color: #fff;
    background: #babec5;
  `}

  ${({ variant }) => variant === 'full' && css`
    color: #455560;
    background: #E8EAF3;
    text-align: center;
    justify-content: center;
    width: 100%;
  `}
`;
