import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LuChevronLeft } from 'react-icons/lu';

import WebSocketManager from 'helpers/WebSocketManager';
import { WS_EVENTS, WS_NAMESPACES } from 'helpers/constants';
import { LoadingIcon } from '_components/_shared';
import { Button } from '_components/_core';

import InvoiceHero from './components/InvoiceHero';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceNotaFiscal from './components/InvoiceNotaFiscal';
import InvoicePayment from './components/InvoicePayment';

function InvoiceDetails({
  subscription,
  originView,
  invoiceId,
  onChangeView,
  onFetchInvoice,
  onFetchCompanies,
  onFetchActiveCompany,
  onUpdateStoreSubscription,
  onRefreshPaymentData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const handleRefreshPaymentData = useCallback(() => {
    setIsRefreshing(true);

    const params = {
      invoice_id: invoice.id,
    };

    const successCallback = data => {
      const { invoice: updatedInvoice } = data;

      setIsRefreshing(false);
      setInvoice(updatedInvoice);
    };

    const errorCallback = () => {
      setIsRefreshing(false);
    };

    onRefreshPaymentData(params, successCallback, errorCallback);
  }, [invoice, onRefreshPaymentData]);

  useEffect(() => {
    if (!invoiceId) {
      return;
    }

    setIsLoading(true);

    onFetchInvoice(invoiceId, foundInvoice => {
      setInvoice(foundInvoice);

      setIsLoading(false);
    });
  }, [onFetchInvoice, invoiceId]);

  useEffect(() => {
    if (!invoice || !subscription) {
      return;
    }

    if (invoice.status === 'paid') {
      return;
    }

    const { id, company_id } = subscription;

    const wsManager = new WebSocketManager(WS_NAMESPACES.SUBSCRIPTION_EVENTS);

    wsManager.connect();

    const params = {
      company_id,
      subscription_id: id,
    };

    wsManager.joinRoom(params);

    wsManager.subscribe(WS_EVENTS.SUBSCRIPTION_UPDATED, data => {
      const paymentAlert = {
        variant: 'success',
        title: 'Recebemos seu pagamento',
        description: 'Obrigado! Seu pagamento foi confirmado.',
        dissmissable: true,
      };

      onFetchCompanies();
      onFetchActiveCompany();
      onUpdateStoreSubscription(data, paymentAlert);
      onChangeView(originView);
    });

    return () => {
      wsManager.disconnect();
    };
  }, [
    subscription,
    onChangeView,
    originView,
    invoice,
    onFetchCompanies,
    onFetchActiveCompany,
    onUpdateStoreSubscription,
  ]);

  return (
    <>
      <Button
        variant="link"
        className="m-0 p-0 d-flex justify-content-center mb-3"
        onClick={() => onChangeView(originView)}
      >
        <LuChevronLeft className="mr-2" />
        Voltar
      </Button>
      {isLoading && <LoadingIcon text="Carregando..." />}
      {invoice && (
        <>
          <InvoiceHeader invoice={invoice} />
          <InvoiceHero invoice={invoice} />
          <InvoicePayment
            invoice={invoice}
            isRefreshing={isRefreshing}
            onRefreshPayment={handleRefreshPaymentData}
            onSetInvoice={setInvoice}
            onFetchInvoice={onFetchInvoice}
          />
          <InvoiceNotaFiscal invoice={invoice} />
        </>
      )}
    </>
  );
}

InvoiceDetails.defaultProps = {
  payments: [],
  activeCompany: {},
  invoice: null,
  originView: 'DEFAULT',
};

InvoiceDetails.propTypes = {
  payments: PropTypes.array,
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  invoice: PropTypes.object,
  originView: PropTypes.string,
  onFetchActiveCompany: PropTypes.func,
  onFetchCompanies: PropTypes.func,
  onUpdateStoreSubscription: PropTypes.func,
  onRefreshPaymentData: PropTypes.func,
  onFetchInvoice: PropTypes.func.isRequired,
};

export default InvoiceDetails;
