import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useHistory } from 'react-router-dom';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import {
  FaChevronLeft,
  FaIdBadge,
  FaPencilAlt,
  FaPlus,
  FaQuestionCircle,
  FaTimes,
  FaTruck,
  FaUser,
} from 'react-icons/fa';
import {
  IoCloudDownload,
  IoCloudUpload,
  IoSearch,
  IoTrash,
  IoTrashBin,
} from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';
import { Button, Card, CopyToClipboard, FastFilterSelect, Tag } from '_components/_core';
import { ContactForm, LoadingIcon, AsyncOperations } from '_components/_shared';
import useAsyncOperations from '_components/_shared/AsyncOperations/useAsyncOperations';

import { useMediaQuery } from 'helpers';
import classNames from 'classnames';
import {
  ActionBar,
  Circle,
  GroupHeader,
  StyledButton,
  StyledFormCheck,
  StyledHr,
  StyledLabel,
  StyledListGroupItem,
  StyledProgressBar,
  StyledSearchInput,
  SummaryTable,
  TabListGroup,
  TabListGroupItem,
  SummaryTable2,
} from './styles';

pluralize.addIrregularRule('item', 'itens');

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 47.5,
});

const TYPES_2 = {
  CUSTOMER: 'Cliente',
  SUPPLIER: 'Fornecedor',
  EMPLOYEE: 'Funcionário',
  [null]: 'Não definido',
};

const TYPES_ADD = {
  CUSTOMER: 'Cliente',
  SUPPLIER: 'Fornecedor',
  EMPLOYEE: 'Funcionário',
  [null]: 'Contato',
};

const TYPES_PLURAL = {
  CUSTOMER: 'Clientes',
  SUPPLIER: 'Fornecedores',
  EMPLOYEE: 'Funcionários',
  [null]: 'Outros',
};

const TYPES_VARIANT = {
  CUSTOMER: 'success',
  SUPPLIER: 'warning',
  EMPLOYEE: 'primary',
  [null]: 'default',
};

const TYPES_ICONS = {
  CUSTOMER: <FaUser />,
  SUPPLIER: <FaTruck />,
  EMPLOYEE: <FaIdBadge />,
  [null]: <FaQuestionCircle />,
};

const TYPES_TAG_ICON = {
  CUSTOMER: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.CUSTOMER}>
      {TYPES_ICONS.CUSTOMER}
    </Tag>
  ),
  SUPPLIER: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.SUPPLIER}>
      {TYPES_ICONS.SUPPLIER}
    </Tag>
  ),
  EMPLOYEE: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.EMPLOYEE}>
      {TYPES_ICONS.EMPLOYEE}
    </Tag>
  ),
  [null]: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.null}>
      {TYPES_ICONS.null}
    </Tag>
  ),
};

const AGE_TRANSLATION = {
  MORE_THAN_60_DAYS: 'mais de 60 dias',
  BETWEEN_30_AND_60_DAYS: 'de 30 a 60 dias',
  LESS_THAN_30_DAYS: 'até 30 dias',
};

const AGE_COLOR_INCOME = {
  MORE_THAN_60_DAYS: '#48C404',
  BETWEEN_30_AND_60_DAYS: '#76DF47',
  LESS_THAN_30_DAYS: '#D7F7C2',
};

const AGE_COLOR_EXPENSE = {
  MORE_THAN_60_DAYS: '#FF8F0E',
  BETWEEN_30_AND_60_DAYS: '#FCBD3A',
  LESS_THAN_30_DAYS: '#FCEDB9',
};

const SUMMARY_CONFIG_INCOME = {
  MORE_THAN_60_DAYS: {
    color: '#48C404',
    id: 'income-3',
  },
  BETWEEN_30_AND_60_DAYS: {
    color: '#76DF47',
    id: 'income-2',
  },
  LESS_THAN_30_DAYS: {
    color: '#D7F7C2',
    id: 'income-1',
  },
};

const SUMMARY_CONFIG_EXPENSE = {
  MORE_THAN_60_DAYS: {
    color: '#FF8F0E',
    id: 'expense-3',
  },
  BETWEEN_30_AND_60_DAYS: {
    color: '#FCBD3A',
    id: 'expense-2',
  },
  LESS_THAN_30_DAYS: {
    color: '#FCEDB9',
    id: 'expense-1',
  },
};

const TYPES_OPTIONS = [
  { value: null, label: 'Todos' },
  { value: 'CUSTOMER', label: 'Clientes' },
  { value: 'SUPPLIER', label: 'Fornecedores' },
  { value: 'EMPLOYEE', label: 'Funcionários' },
];

const ROW_HEIGHT = 47.5;

function Contacts({
  isLoading,
  allRecipients,
  activeCompany,
  onFetchContact,
  onFetchContacts,
  onFetchContactsSummary,
  onDeleteContacts,
  onDeleteContact,
  onCreateContact,
  onUpdateMultipleContacts,
  onFetchContactsSummaryDetail,
  onExportContacts,
}) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge } =
    useMediaQuery();

  const { hasPending, pendingAsyncOperation, onSetAsyncOperations } = useAsyncOperations({
    type: 'EXPORT_CONTACTS_SPREADSHEET',
    successTitle: 'Back-up concluído!',
    successMessage: 'Seu back-up foi gerado com sucesso.',
    downloadFile: true,
    showConfirmation: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedEditContact, setSelectedEditContact] = useState({});
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [type, setType] = useState(null);
  const [textFilter, setTextFilter] = useState('');
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [summary, setSummary] = useState({
    incomes: [],
    expenses: [],
  });

  const handleAddContact = useCallback(() => {
    setSelectedEditContact({});

    setIsOpen(true);
  }, []);

  const handleDeleteContact = useCallback(
    (e, contactId) => {
      e.stopPropagation();

      onDeleteContact(contactId, () => {
        setSelectedContact({});
      });
    },
    [onDeleteContact],
  );

  const handleEditContact = useCallback(
    (e, contactId) => {
      e.stopPropagation();

      if (contactId) {
        setSelectedEditContact({
          id: contactId,
        });

        setIsOpen(true);

        return;
      }

      setSelectedEditContact(selectedContact);

      setIsOpen(true);
    },
    [selectedContact],
  );

  useEffect(() => {
    setSelectedContact({});

    onFetchContacts();

    onFetchContactsSummary(foundSummary => {
      setSummary(foundSummary);
    });
  }, [onFetchContacts, onFetchContactsSummary, activeCompany]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const recipientId = urlParams.get('recipient_id');

    if (recipientId) {
      setSelectedEditContact({
        id: recipientId,
      });

      setIsOpen(true);

      urlParams.delete('recipient_id');

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [onFetchContact, handleEditContact]);

  const handleToggleForm = useCallback(() => {
    const newState = !isOpen;

    if (!newState) {
      setSelectedEditContact({});
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleTabChange = useCallback(newType => {
    setType(newType);

    setSelectedItems([]);
  }, []);

  const handleItemSelected = useCallback(
    (e, id) => {
      e.stopPropagation();

      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter(item => item !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems],
  );

  const handleSelectMultiple = useCallback(
    (e, ids) => {
      e.stopPropagation();

      if (
        selectedItems.length === ids.length &&
        selectedItems.every(item => ids.includes(item))
      ) {
        setSelectedItems([]);
      } else {
        setSelectedItems(ids.filter(item => item !== null));
      }
    },
    [selectedItems],
  );

  const filteredRecipients = useMemo(() => {
    let filtered = allRecipients;

    if (type) {
      filtered = filtered.filter(recipient => recipient.type === type);
    }

    if (textFilter) {
      filtered = filtered.filter(recipient =>
        recipient.name.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }

    const TYPE_ORDER = ['CUSTOMER', 'SUPPLIER', 'EMPLOYEE', null];

    const sortByType = recipient => TYPE_ORDER.indexOf(recipient.type);
    const sortByName = recipient => recipient.name;

    const sorted = sortBy(filtered, [sortByType, sortByName]);

    let counts = {
      CUSTOMER: 0,
      SUPPLIER: 0,
      EMPLOYEE: 0,
      null: 0,
    };

    sorted.forEach(recipient => {
      counts = {
        ...counts,
        [recipient.type]: counts[recipient.type] + 1,
      };
    });

    const hasCustomer = counts.CUSTOMER > 0;
    const hasSupplier = counts.SUPPLIER > 0;
    const hasEmployee = counts.EMPLOYEE > 0;
    const hasUnidentified = counts.null > 0;

    if (hasCustomer) {
      const customerIndex = sorted.findIndex(recipient => recipient.type === 'CUSTOMER');

      if (customerIndex > -1) {
        sorted.splice(customerIndex, 0, {
          key: 'group_row_customer',
          type: 'CUSTOMER',
          name: 'Clientes',
          isGroup: true,
          length: counts.CUSTOMER,
        });
      }
    }

    if (hasSupplier) {
      const supplierIndex = sorted.findIndex(recipient => recipient.type === 'SUPPLIER');

      if (supplierIndex > -1) {
        sorted.splice(supplierIndex, 0, {
          key: 'group_row_supplier',
          type: 'SUPPLIER',
          name: 'Fornecedores',
          isGroup: true,
          length: counts.SUPPLIER,
        });
      }
    }

    if (hasEmployee) {
      const employeeIndex = sorted.findIndex(recipient => recipient.type === 'EMPLOYEE');

      if (employeeIndex > -1) {
        sorted.splice(employeeIndex, 0, {
          key: 'group_row_employee',
          type: 'EMPLOYEE',
          name: 'Funcionários',
          isGroup: true,
          length: counts.EMPLOYEE,
        });
      }
    }

    if (hasUnidentified) {
      const unidentifiedIndex = sorted.findIndex(recipient => recipient.type === null);

      if (unidentifiedIndex > -1) {
        sorted.splice(unidentifiedIndex, 0, {
          key: 'group_row_unidentified',
          type: null,
          name: 'Outros',
          isGroup: true,
          length: counts.null,
        });
      }
    }

    return sorted;
  }, [allRecipients, type, textFilter]);

  const handleSelectContact = useCallback(
    contact => {
      setIsLoadingContact(true);

      onFetchContact(contact.id, foundContact => {
        setSelectedContact({
          ...foundContact,
        });

        setIsLoadingContact(false);
      });
    },
    [onFetchContact],
  );

  const handleCreatesSimpleContact = useCallback(() => {
    onCreateContact(
      {
        name: textFilter || 'Novo contato',
        type: type || null,
      },
      contact => {
        setSelectedContact(contact);
      },
    );

    setSelectedItems([]);
  }, [onCreateContact, type, textFilter]);

  const fullAddress = useMemo(() => {
    if (!selectedContact || isEmpty(selectedContact)) {
      return '';
    }

    const {
      address_street,
      address_number,
      address_complement,
      address_district,
      address_city,
      address_state,
      address_zip_code,
    } = selectedContact;

    return `${address_street}, ${address_number}, ${address_complement || ''},  ${address_district} ${address_city} - ${address_state} ${address_zip_code}`;
  }, [selectedContact]);

  const renderRow = useCallback(
    ({ index, key, style, parent }) => {
      const recipientRow = filteredRecipients[index];

      if (!recipientRow) {
        return null;
      }

      const { isGroup } = recipientRow;

      if (isGroup) {
        const ids = filteredRecipients
          .filter(recipient => recipient.type === recipientRow.type && recipient.id)
          .map(recipient => recipient.id);

        return (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ registerChild }) => (
              <GroupHeader style={style} ref={registerChild}>
                <StyledFormCheck
                  type="checkbox"
                  checked={
                    selectedItems.length > 0 &&
                    ids.every(item => selectedItems.includes(item))
                  }
                  onClick={e => handleSelectMultiple(e, ids)}
                />
                <Tag
                  className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2"
                  variant={TYPES_VARIANT[recipientRow.type]}
                >
                  {TYPES_ICONS[recipientRow.type]}
                </Tag>
                <h5 className="m-0">
                  {TYPES_PLURAL[recipientRow.type]} ({recipientRow.length})
                </h5>
              </GroupHeader>
            )}
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          {({ registerChild }) => (
            <StyledListGroupItem
              style={style}
              className="row"
              action
              onClick={() => handleSelectContact(recipientRow)}
              ref={registerChild}
              isSelected={selectedItems.includes(recipientRow.id)}
              isActive={selectedContact && selectedContact.id === recipientRow.id}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex">
                  <StyledFormCheck
                    type="checkbox"
                    checked={selectedItems.includes(recipientRow.id)}
                    onClick={e => handleItemSelected(e, recipientRow.id)}
                  />
                  <span className="d-flex flex-column ml-2">
                    <span style={{ fontWeight: 600, color: '#071437' }}>
                      {FORMATTERS.MAX_X_CHARS(recipientRow.name, 30)}
                    </span>
                  </span>
                </span>
                <span className="text-muted">
                  <Button
                    size="sm"
                    variant="default"
                    className="m-1 p-1 text-info bg-transparent"
                    onClick={e => handleEditContact(e, recipientRow.id)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    size="sm"
                    variant="default"
                    className="m-1 p-1 text-danger bg-transparent"
                    onClick={e => handleDeleteContact(e, recipientRow.id)}
                  >
                    <FaTimes />
                  </Button>
                </span>
              </div>
            </StyledListGroupItem>
          )}
        </CellMeasurer>
      );
    },
    [
      filteredRecipients,
      selectedItems,
      selectedContact,
      handleSelectMultiple,
      handleSelectContact,
      handleItemSelected,
      handleEditContact,
      handleDeleteContact,
    ],
  );

  const renderCheckbox = useCallback(
    () => (
      <StyledFormCheck
        type="checkbox"
        checked={
          filteredRecipients.length > 0 &&
          selectedItems.length === filteredRecipients.length
        }
        onClick={() => {
          if (selectedItems.length === filteredRecipients.length) {
            setSelectedItems([]);
          } else {
            setSelectedItems(
              filteredRecipients
                .filter(f => f.id !== null)
                .map(recipient => recipient.id),
            );
          }
        }}
      />
    ),
    [filteredRecipients, selectedItems],
  );

  const handleChangeType = useCallback(
    (ids, type) => {
      const params = {
        ids,
        type,
      };

      onUpdateMultipleContacts(params, () => {
        setSelectedItems([]);
      });
    },
    [onUpdateMultipleContacts],
  );

  const handleViewTransactions = useCallback(
    (summaryItem, type) => {
      const { age_bracket } = summaryItem;

      const params = {
        type,
        age_bracket,
      };

      onFetchContactsSummaryDetail(params, found => {
        setPendingTransactions(found);

        setSelectedFilter({
          type,
          age_bracket,
        });
      });
    },
    [onFetchContactsSummaryDetail],
  );

  const handleExportContacts = useCallback(() => {
    onExportContacts(asyncOperation => {
      onSetAsyncOperations([asyncOperation]);
    });
  }, [onExportContacts, onSetAsyncOperations]);

  const COLUMN_SIZES = useMemo(() => {
    if (isMobile) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    if (isTablet) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    if (isDesktopMedium) {
      return {
        LEFT: 6,
        RIGHT: 6,
      };
    }

    if (isDesktopLarge) {
      return {
        LEFT: 5,
        RIGHT: 7,
      };
    }

    if (isDesktopExtraLarge) {
      return {
        LEFT: 4,
        RIGHT: 8,
      };
    }

    return {
      LEFT: 3,
      RIGHT: 0,
    };
  }, [isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge]);

  const CARDS_COLUMN_SIZES = useMemo(() => {
    if (isDesktopMedium) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    return {
      LEFT: 6,
      RIGHT: 6,
    };
  }, [isDesktopMedium]);

  const SUMMARY_TOTAL_INCOME = useMemo(
    () => summary.incomes.reduce((acc, curr) => acc + curr.amount, 0),
    [summary],
  );
  const SUMMARY_TOTAL_EXPENSE = useMemo(
    () => summary.expenses.reduce((acc, curr) => acc + curr.amount, 0),
    [summary],
  );

  const getItemTotalIncome = useCallback(
    item => {
      const total = SUMMARY_TOTAL_INCOME;

      if (total === 0) {
        return 0;
      }

      return (item.amount / total) * 100;
    },
    [SUMMARY_TOTAL_INCOME],
  );

  const getItemTotalExpense = useCallback(
    item => {
      const total = SUMMARY_TOTAL_EXPENSE;

      if (total === 0) {
        return 0;
      }

      return (item.amount / total) * 100;
    },
    [SUMMARY_TOTAL_EXPENSE],
  );

  return (
    <Container fluid className="content-wrapper fixedHeader h-100">
      <AsyncOperations
        className="mb-3"
        hasPending={hasPending}
        pendingAsyncOperation={pendingAsyncOperation}
      />
      <Row className="h-100">
        <Col sm={COLUMN_SIZES.LEFT}>
          <div
            className="bg-white d-flex align-items-center flex-column"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.125)',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }}
          >
            <div className="d-flex align-items-center justify-content-between w-100 p-3">
              <h5 className="m-0">Lista de contatos</h5>
              <div className="d-flex">
                {!isMobile && !isTablet && (
                  <>
                    <StyledButton
                      variant="inverse-dark"
                      icon={<IoCloudDownload size="1.2em" />}
                      iconPosition="left"
                      disabled={isLoading}
                      onClick={handleExportContacts}
                      className="mr-2"
                      size="sm"
                    >
                      Exportar
                    </StyledButton>
                    <StyledButton
                      variant="inverse-dark"
                      icon={<IoCloudUpload size="1.2em" />}
                      iconPosition="left"
                      disabled={isLoading}
                      onClick={() => history.push('/importar-contatos')}
                      className="mr-2"
                      size="sm"
                    >
                      Importar
                    </StyledButton>
                  </>
                )}
                <Button
                  onClick={handleAddContact}
                  className="btn btn-sm d-flex justify-content-center align-items-center btn-success"
                  variant="success-2"
                  size="xs"
                  style={{ fontSize: '13px' }}
                >
                  <FaPlus className="mr-2" />
                  Criar novo
                </Button>
              </div>
            </div>
            <div className="w-100 pl-3 pr-3">
              <div
                className="d-flex align-items-center justify-content-between w-100"
                style={{
                  border: '1px solid #E8E7EA',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                }}
              >
                <IoSearch size="1.2em" className="ml-2" />
                <StyledSearchInput
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar contatos"
                  onChange={e => setTextFilter(e.target.value)}
                  value={textFilter}
                />
                {textFilter && (
                  <IoTrash
                    className="text-danger mr-2"
                    size="1.2em"
                    onClick={() => setTextFilter('')}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )}
              </div>
            </div>
            {selectedItems.length > 0 && (
              <ActionBar className="mt-2">
                <>
                  <div className="d-flex align-items-center">
                    {renderCheckbox()}
                    <p>
                      <strong>{selectedItems.length}</strong> selecionados
                    </p>
                  </div>
                  <FastFilterSelect
                    triggerStyle={{
                      '&:hover': {
                        backgroundColor: '#ffd !important',
                      },
                    }}
                    menuStyle={{ minWidth: '280px' }}
                    label="Ações"
                    value={`${selectedItems.length} selecionados`}
                    options={[
                      {
                        icon: TYPES_TAG_ICON.CUSTOMER,
                        value: 'SET_CUSTOMER',
                        label: `Marcar ${selectedItems.length} como cliente`,
                      },
                      {
                        icon: TYPES_TAG_ICON.SUPPLIER,
                        value: 'SET_SUPPLIER',
                        label: `Marcar ${selectedItems.length} como fornecedor`,
                      },
                      {
                        icon: TYPES_TAG_ICON.EMPLOYEE,
                        value: 'SET_EMPLOYEE',
                        label: `Marcar ${selectedItems.length} como funcionário`,
                      },
                      {
                        icon: (
                          <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant="danger">
                            <IoTrashBin size="1.2em" />
                          </Tag>
                        ),
                        value: 'DELETE',
                        label: `Excluir ${selectedItems.length} ${pluralize('item', selectedItems.length)}`,
                      },
                    ]}
                    onChange={value => {
                      switch (value) {
                        case 'DELETE': {
                          const params = {
                            ids: selectedItems,
                          };

                          onDeleteContacts(params, () => {
                            setSelectedItems([]);

                            if (
                              selectedContact &&
                              selectedItems.includes(selectedContact.id)
                            ) {
                              setSelectedContact({});
                            }
                          });

                          break;
                        }
                        case 'SET_CUSTOMER': {
                          handleChangeType(selectedItems, 'CUSTOMER');
                          break;
                        }
                        case 'SET_SUPPLIER': {
                          handleChangeType(selectedItems, 'SUPPLIER');
                          break;
                        }
                        case 'SET_EMPLOYEE': {
                          handleChangeType(selectedItems, 'EMPLOYEE');
                          break;
                        }
                        default:
                          break;
                      }
                    }}
                  />
                </>
              </ActionBar>
            )}
            {selectedItems.length === 0 && (
              <TabListGroup className="mt-2">
                {TYPES_OPTIONS.map(option => (
                  <TabListGroupItem
                    active={type === option.value}
                    action
                    onClick={() => handleTabChange(option.value)}
                  >
                    {option.label}
                  </TabListGroupItem>
                ))}
              </TabListGroup>
            )}
          </div>
          {filteredRecipients.length === 0 && (
            <div className="bg-white d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
              <h6 className="">Nenhum contato encontrado</h6>
              <Button
                variant="success-2"
                className="mt-2"
                onClick={handleCreatesSimpleContact}
              >
                {textFilter
                  ? `Adicionar "${textFilter}"`
                  : `Adicionar ${TYPES_ADD[type]}`}
              </Button>
            </div>
          )}
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height - 200}
                rowHeight={ROW_HEIGHT}
                rowRenderer={renderRow}
                rowCount={filteredRecipients.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </Col>
        {!isMobile && !isTablet && (
          <Col sm={COLUMN_SIZES.RIGHT}>
            <div style={{ position: 'sticky', top: '60px' }}>
              {isEmpty(selectedContact) && !isLoadingContact && (
                <>
                  <Row>
                    <Col sm={CARDS_COLUMN_SIZES.LEFT}>
                      <Card className="p-3 mb-3">
                        <div className="w-100 flex-column d-flex justify-content-between align-items-start">
                          <p className="text-success p-0 m-0">Quem está me devendo?</p>
                          <h3 className="m-0 mt-2">
                            {FORMATTERS.NUMBER(SUMMARY_TOTAL_INCOME)}
                          </h3>
                        </div>
                        <StyledProgressBar className="mt-2">
                          {summary.incomes.map((item, index) => (
                            <StyledProgressBar
                              key={`income${index}`}
                              id={SUMMARY_CONFIG_INCOME[item.age_bracket].id}
                              now={getItemTotalIncome(item)}
                            />
                          ))}
                        </StyledProgressBar>
                        <SummaryTable className="mt-3 table table-hover">
                          <thead>
                            <tr>
                              <th className="text-left">VENCIDO HÁ</th>
                              <th className="text-right">QTDE</th>
                              <th className="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summary.incomes.map((summaryItem, index) => (
                              <tr
                                key={`income_${index}`}
                                className={classNames({
                                  active:
                                    selectedFilter.type === 'INCOME' &&
                                    selectedFilter.age_bracket ===
                                      summaryItem.age_bracket,
                                })}
                                onClick={() =>
                                  handleViewTransactions(summaryItem, 'INCOME')
                                }
                              >
                                <td className="text-left">
                                  <span className="d-flex align-items-center">
                                    <Circle
                                      color={AGE_COLOR_INCOME[summaryItem.age_bracket]}
                                    />
                                    {AGE_TRANSLATION[summaryItem.age_bracket]}
                                  </span>
                                </td>
                                <td className="text-right">
                                  {summaryItem.contacts_count}
                                </td>
                                <td className="text-right">
                                  {FORMATTERS.NUMBER(summaryItem.amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </SummaryTable>
                      </Card>
                    </Col>
                    <Col sm={CARDS_COLUMN_SIZES.RIGHT}>
                      <Card className="p-3 mb-3">
                        <div className="w-100 flex-column d-flex justify-content-between align-items-start">
                          <p className="text-danger p-0 m-0">Quem estou devendo?</p>
                          <h3 className="m-0 mt-2">
                            {FORMATTERS.NUMBER(SUMMARY_TOTAL_EXPENSE)}
                          </h3>
                        </div>
                        <StyledProgressBar className="mt-2">
                          {summary.expenses.map((item, index) => (
                            <StyledProgressBar
                              key={`expense_${index}`}
                              id={SUMMARY_CONFIG_EXPENSE[item.age_bracket].id}
                              now={getItemTotalExpense(item)}
                            />
                          ))}
                        </StyledProgressBar>
                        <SummaryTable className="mt-3 table table-hover">
                          <thead>
                            <tr>
                              <th className="text-left">VENCIDO HÁ</th>
                              <th className="text-right">QTDE</th>
                              <th className="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summary.expenses.map((summaryItem, index) => (
                              <tr
                                key={`expense_${index}`}
                                className={classNames({
                                  active:
                                    selectedFilter.type === 'EXPENSE' &&
                                    selectedFilter.age_bracket ===
                                      summaryItem.age_bracket,
                                })}
                                onClick={() =>
                                  handleViewTransactions(summaryItem, 'EXPENSE')
                                }
                              >
                                <td className="text-left">
                                  <span className="d-flex align-items-center">
                                    <Circle
                                      color={AGE_COLOR_EXPENSE[summaryItem.age_bracket]}
                                    />
                                    {AGE_TRANSLATION[summaryItem.age_bracket]}
                                  </span>
                                </td>
                                <td className="text-right">
                                  {summaryItem.contacts_count}
                                </td>
                                <td className="text-right">
                                  {FORMATTERS.NUMBER(summaryItem.amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </SummaryTable>
                      </Card>
                    </Col>
                  </Row>
                  {!isEmpty(selectedFilter) && (
                    <Row>
                      <Col>
                        <Card className="p-3 mb-5">
                          {selectedFilter && selectedFilter.type === 'INCOME' && (
                            <div className="d-flex">
                              <p className="text-success p-0 m-0 mr-1">
                                Quem está me devendo:
                              </p>
                              <p className="p-0 m-0 ml-1 font-weight-bold">
                                {AGE_TRANSLATION[selectedFilter.age_bracket]}
                              </p>
                            </div>
                          )}
                          {selectedFilter && selectedFilter.type === 'EXPENSE' && (
                            <div className="d-flex">
                              <p className="text-danger p-0 m-0 mr-1">
                                Quem estou devendo:
                              </p>
                              <p className="p-0 m-0 ml-1 font-weight-bold">
                                {AGE_TRANSLATION[selectedFilter.age_bracket]}
                              </p>
                            </div>
                          )}
                          {isEmpty(pendingTransactions) && (
                            <p className="text-muted mt-3">Nenhum contato encontrado</p>
                          )}
                          {!isEmpty(pendingTransactions) && (
                            <SummaryTable2 className="mt-3 table table-hover">
                              <thead>
                                <tr>
                                  <th className="text-left">Nome do contato</th>
                                  <th className="text-right">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {pendingTransactions.map(pendingTransaction => (
                                  <tr>
                                    <td className="text-left">
                                      <span className="d-flex align-items-center">
                                        {pendingTransaction.name}
                                      </span>
                                    </td>
                                    <td className="text-right">
                                      <b>
                                        {FORMATTERS.NUMBER(pendingTransaction.amount)}
                                      </b>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className="text-left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>
                                      {FORMATTERS.NUMBER(
                                        pendingTransactions.reduce(
                                          (acc, curr) => acc + curr.amount,
                                          0,
                                        ),
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              </tfoot>
                            </SummaryTable2>
                          )}
                        </Card>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {!isEmpty(selectedContact) && !isLoadingContact && (
                <div className="w-100 d-flex align-items-center justify-content-start mb-3">
                  <Button
                    variant="link"
                    className="p-0 m-0 d-flex align-items-center justify-content-center"
                    onClick={() => setSelectedContact(null)}
                  >
                    <FaChevronLeft className="mr-2" />
                    Voltar
                  </Button>
                </div>
              )}
              {isLoadingContact && <LoadingIcon />}
              {!isEmpty(selectedContact) && !isLoadingContact && (
                <Card className="p-3">
                  <Row>
                    <Col>
                      <div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                          <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-3">
                            <div>
                              <Tag
                                className="p-0 pl-2 pr-2 pt-1 pb-1"
                                variant={TYPES_VARIANT[selectedContact.type]}
                              >
                                {TYPES_2[selectedContact.type] || '--'}
                              </Tag>
                              <h3 className="mt-3" style={{ lineBreak: 'anywhere' }}>
                                {selectedContact.name}
                              </h3>
                            </div>
                            <div>
                              <Button
                                variant="inverse-dark"
                                icon={<FaPencilAlt size="1.2em" />}
                                iconPosition="left"
                                disabled={isLoading}
                                onClick={e => handleEditContact(e, selectedContact.id)}
                                className="mr-2"
                                size="sm"
                              >
                                Editar
                              </Button>
                            </div>
                          </div>
                          <StyledLabel>Detalhes</StyledLabel>
                          <StyledHr />
                          <div>
                            <div className="mt-2">
                              <StyledLabel>
                                {selectedContact.document_type || 'CPF/CNPJ '}
                              </StyledLabel>
                              <p className="text-muted">
                                <CopyToClipboard
                                  text={selectedContact.document_number}
                                  emptyText="Sem documento"
                                >
                                  {FORMATTERS.CPF_CNPJ(selectedContact.document_number)}
                                </CopyToClipboard>
                              </p>
                            </div>
                            <div>
                              <StyledLabel>E-mail</StyledLabel>
                              <p className="text-muted">
                                <CopyToClipboard
                                  text={selectedContact.email}
                                  emptyText="Sem e-mail"
                                >
                                  <a
                                    href={`mailto:${selectedContact.email}`}
                                    className="text-muted text-hover-primary"
                                  >
                                    {selectedContact.email}
                                  </a>
                                </CopyToClipboard>
                              </p>
                            </div>
                            <div>
                              <StyledLabel>Telefone</StyledLabel>
                              <p className="text-muted">
                                <CopyToClipboard
                                  text={selectedContact.phone_number}
                                  emptyText="Sem telefone"
                                >
                                  <a
                                    href={`tel:${selectedContact.phone_number}`}
                                    className="text-muted text-hover-primary"
                                  >
                                    {selectedContact.phone_number}
                                  </a>
                                </CopyToClipboard>
                              </p>
                            </div>
                            <div>
                              <StyledLabel>Endereço</StyledLabel>
                              {selectedContact.address_street && (
                                <address className="mb-2 text-muted">
                                  <p className="text-muted d-flex align-items-center">
                                    <CopyToClipboard
                                      text={fullAddress}
                                      emptyText="Sem endereço"
                                    >
                                      {`${selectedContact.address_street} ${selectedContact.address_number} ${selectedContact.address_complement || ''}`}
                                    </CopyToClipboard>
                                  </p>
                                  <p className="text-muted d-flex align-items-center">
                                    {`${selectedContact.address_district}`}
                                  </p>
                                  <p className="text-muted d-flex align-items-center">
                                    {`${selectedContact.address_city} - ${selectedContact.address_state}`}
                                  </p>
                                  <p className="text-muted d-flex align-items-center">
                                    {selectedContact.address_zip_code}
                                  </p>
                                </address>
                              )}
                            </div>
                            <p className="text-muted">
                              {!selectedContact.address_street && 'Sem endereço'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          </Col>
        )}
      </Row>
      <ContactForm
        isOpen={isOpen}
        isLoading={isLoading}
        contact={selectedEditContact}
        contact_id={selectedEditContact.id}
        onToggleForm={handleToggleForm}
        onAfterSaveCallbackWithReset={contact_related => {
          if (contact_related) {
            setSelectedContact(contact_related);
          }

          handleToggleForm();
        }}
      />
    </Container>
  );
}

Contacts.defaultProps = {
  allRecipients: [],
};

Contacts.propTypes = {
  isLoading: PropTypes.func,
  activeCompany: PropTypes.object,
  allRecipients: PropTypes.array,
  onFetchContact: PropTypes.func.isRequired,
  onFetchContacts: PropTypes.func.isRequired,
  onFetchContactsSummary: PropTypes.func.isRequired,
  onDeleteContacts: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  onCreateContact: PropTypes.func.isRequired,
  onUpdateMultipleContacts: PropTypes.func.isRequired,
  onFetchContactsSummaryDetail: PropTypes.func.isRequired,
  onExportContacts: PropTypes.func.isRequired,
};

export default Contacts;
