const isBillingInfoValid = billing_info => {
  const {
    name,
    document_type,
    document_number,
    email_primary,
    address_street,
    address_number,
    address_district,
    address_city,
    address_city_ibge,
    address_state,
    address_state_ibge,
    address_zip_code,
  } = billing_info || {};

  if (
    !name ||
    !document_type ||
    !document_number ||
    !email_primary ||
    !address_zip_code ||
    !address_number ||
    !address_street ||
    !address_district ||
    !address_state ||
    !address_state_ibge ||
    !address_city ||
    !address_city_ibge
  ) {
    return false;
  }

  return true;
};

export default isBillingInfoValid;
