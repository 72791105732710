import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormSelectField, FormSwitchField, FormTextField, Tag } from '_components/_core';

// const ASAAS_FEATURES = [
//   'Cobranças avulsas',
//   'Cobranças parceladas',
//   'Cobranças recorrentes',
//   'Clientes',
//   'Notificações de cobrança',
//   'Emissão de notas fiscais',
// ];

function AsaasFields({ values }) {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} lg="12">
          <Form.Label>
            <span className="text-danger">Chave de API (Produção)</span>
          </Form.Label>
          <FormTextField
            name="provider_settings.production_api_key"
            placeholder="Chave de API - Produção"
          />
        </Form.Group>
        <Form.Group as={Col} lg="12">
          <Form.Label>Chave de API (Sandbox)</Form.Label>
          <FormTextField
            name="provider_settings.sandbox_api_key"
            placeholder="Chave de API - Sandbox"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg="6">
          <Form.Label>Utilizar o ambiente</Form.Label>
          <FormSelectField
            name="provider_settings.environment"
            options={[
              { value: 'sandbox', label: 'Sandbox (ambiente de testes)' },
              { value: 'production', label: 'Produção' },
            ]}
          />
        </Form.Group>
        <Form.Group as={Col} lg="6">
          <Form.Label>Status</Form.Label>
          <div className="d-flex justify-content-start align-items-center">
            <FormSwitchField name="enabled" />
            {values.enabled && (
              <Tag className="ml-3" variant="success">
                Ativado
              </Tag>
            )}
            {!values.enabled && (
              <Tag className="ml-3" variant="danger">
                Desativado
              </Tag>
            )}
          </div>
        </Form.Group>
      </Form.Row>
    </>
  );
}

export default AsaasFields;
