import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import isEmpty from 'lodash/isEmpty';
import { FaDownload } from 'react-icons/fa';
import classNames from 'classnames';

import { useMediaQuery } from 'helpers';

import FORMATTERS from 'helpers/formatters';
import { Card, PageHeader } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import { StyledTable } from './styles';

function Payments({ payments, isLoading, onFetchPayments, isCompact }) {
  useEffect(() => {
    onFetchPayments();
  }, [onFetchPayments]);

  const { isMobile } = useMediaQuery();

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      <PageHeader title="Histórico de Pagamentos" variant="small" />
      {!isLoading && isEmpty(payments) && (
        <div className="mt-5 mb-5">
          <h4 className="text-center">Nenhum registro encontrado</h4>
        </div>
      )}
      {isLoading && (
        <div className="mt-5 mb-5">
          <LoadingIcon text="Carregando..." />
        </div>
      )}
      {!isLoading && !isEmpty(payments) && (
        <>
          <Row>
            <Col>
              <Card className="p-0">
                <StyledTable
                  className={`table table-hover ${isMobile ? 'table-responsive' : ''} `}
                >
                  <thead>
                    <tr>
                      <th rowSpan={2} width="5%" className="text-center">
                        Data
                      </th>
                      <th rowSpan={2} width="5%" className="text-center">
                        Valor
                      </th>
                      <th rowSpan={2} width="5%" className="text-center">
                        Situação
                      </th>
                      <th rowSpan={2} width="5%" className="text-center">
                        Duração
                      </th>
                      <th rowSpan={2} width="10%" className="text-left">
                        Serviço
                      </th>
                      <th rowSpan={2} width="10%" className="text-left">
                        Responsável
                      </th>
                      <th colSpan={3} width="6%" className="text-center">
                        Nota Fiscal
                      </th>
                    </tr>
                    <tr>
                      <th width="2%" className="text-center">
                        Status
                      </th>
                      <th width="2%" className="text-center">
                        PDF
                      </th>
                      <th width="2%" className="text-center">
                        XML
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map(payment => (
                      <tr key={payment._id}>
                        <td className="text-center">
                          {FORMATTERS.DATE_DDMMYYYY(payment.payment_date)}
                        </td>
                        <td className="text-center">
                          <strong>{FORMATTERS.NUMBER(payment.payment_amount)}</strong>
                        </td>
                        <td className="text-center">
                          {FORMATTERS.PAYMENT_PAYMENT_STATUS(payment.payment_status)}
                        </td>
                        <td className="text-center">
                          {FORMATTERS.PAYMENT_DURATION(payment)}
                        </td>
                        <td className="text-left">
                          {FORMATTERS.PAYMENT_PROVIDER_REASON(
                            payment.payment_provider_reason,
                          )}
                        </td>

                        <td className="text-left">
                          {FORMATTERS.PAYMENT_MANAGER(payment.manager)}
                        </td>
                        <td className="text-center">
                          {FORMATTERS.PAYMENT_INVOICE_STATUS(payment.invoice_status)}
                        </td>
                        <td className="text-center">
                          <a
                            target="_blank"
                            href={payment.invoice_pdf_url}
                            rel="noreferrer"
                          >
                            <FaDownload />
                          </a>
                        </td>
                        <td className="text-center">
                          <a
                            target="_blank"
                            href={payment.invoice_xml_url}
                            rel="noreferrer"
                          >
                            <FaDownload />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

Payments.defaultProps = {
  payments: [],
  isLoading: false,
  isCompact: false,
};

Payments.propTypes = {
  payments: PropTypes.array,
  isLoading: PropTypes.bool,
  onFetchPayments: PropTypes.func,
  isCompact: PropTypes.bool,
};

export default Payments;
