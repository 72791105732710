import styled, { css } from 'styled-components';

import Tag from '_components/_core/Tag/Tag';
import { Card } from '_components/_core';

export const StyledTable = styled.table`  
  &&& {
    font-size: 14px;

    /* thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: 1rem .75rem;
    } */

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: 1rem .75rem;
      vertical-align: middle;
      border-top: 1px solid #e7eaef;
      font-weight: 400;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const Dot = styled.span`
  padding-left: 3px;
  padding-right: 3px;
`;

export const RoleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoleName = styled(Tag)`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const CustomCard = styled(Card)`
  border: 1px solid #F1F1F4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding-bottom: 0;
`;

export const CustomCardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #F1F1F4;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #071437;
  }
`;

export const CustomCardBody = styled.div`
  padding: 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}

`;

export const CustomCardFooter = styled.div`
  
`;

export const Label = styled.div`
  font-weight: 600;
  color: #99A1B7;
  font-size: 13px;
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #252F4A;
  width: fit-content;
`;

export const CompanyDetails = styled.div`
  display: flex;
  align-items: center;

  /* img {
    width: 45px;
    height: 45px;
    border-radius: 0.45rem;
    margin-right: 1rem;
  } */

  span.user-name {
    color: #071437;
    font-weight: 600;
    font-size: 1.075rem !important;
  }

  span.user-email {
    color: #99A1B7;
    font-weight: 500;
    font-size: .95rem !important;
  }
`;

export const CompanyName = styled.span`
  color: #192939;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

export const CompanyEmail = styled.span`
  color: #6b7c93;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const CompanyAvatar = styled.img`
  
`;

export const CompaniesListMobile = styled.div`

`;

export const CompanyListItemMobile = styled.div`
  border-bottom: 1px solid #f1f1f4;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyListItemMobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  strong {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    text-transform: capitalize;
  }

  small {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #99A1B7;
  }
`;

export const SubscriptionStatus = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #99A1B7;

  ${({ status }) =>
    status === 'active' &&
    css`
      color: #00ab6f;
  `}

  ${({ status }) =>
    status === 'trialing' &&
    css`
      color: #0997d9;
  `}

  ${({ status }) =>
    status === 'trial-ended' &&
    css`
      color: #d46b08;
  `}

  ${({ status }) =>
    status === 'canceled' &&
    css`
      color: #b82a23;
  `}
`;
