import styled, { css } from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export const StyledOverlayTrigger = styled(OverlayTrigger)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPopover = styled(Popover)`
  min-width: 210px;
  padding: 0 !important;
  color: rgb(42, 46, 52);
  font-family: var(--font-family);
  z-index: 9999;

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    padding: 4px !important;
    
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }
`;

export const MenuEntry = styled.div`
  border-radius: 4px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ disabled }) =>
    disabled &&
    css`
    opacity: 0.5;
    pointer-events: none;
  `}

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:first-child) {
    margin-top: 3px;
  }
`;

export const StyledMenuText = styled.h1`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
`;
