import { webhooksConstants } from '_store/_constants';

const INITIAL_STATE = {
  webhooks: [],
  webhook: {},
  isLoading: false,
  events: [],
  eventsTotal: 0,
};

export function webhooks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case webhooksConstants.WEBHOOKS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.WEBHOOKS_GET_ALL_SUCCESS:
      return {
        ...state,
        webhooks: action.payload,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.WEBHOOKS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.WEBHOOKS_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.WEBHOOKS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.WEBHOOKS_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.FETCH_WEBHOOK_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.FETCH_WEBHOOK_EVENTS_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        events: response.data,
        eventsTotal: response.total,
        isLoading: false,
      };
    }

    case webhooksConstants.FETCH_WEBHOOK_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.RESEND_WEBHOOK_EVENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.RESEND_WEBHOOK_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.RESEND_WEBHOOK_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case webhooksConstants.DELETE_WEBHOOK_EVENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case webhooksConstants.DELETE_WEBHOOK_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.webhook_event_id === action.payload.id
            ? { ...event, event_status: 'IGNORED' }
            : event
        ),
        isLoading: false,
      };
    
    case webhooksConstants.DELETE_WEBHOOK_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
