import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/webhooks.constants';
import service from '../../../services/webhooks.service';

import alertActionCreators from '../alert.actions';

const deleteWebhookEvent = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Remover Evento',
      message: 'Tem certeza que deseja excluir este Evento? Esta ação não poderá ser desfeita.',
      confirmButtonText: 'Sim, excluir',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteWebhookEvent(id).then(
          (response) => {
            dispatch(success(response));
            dispatch(alertActionCreators.success('Evento excluído com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_WEBHOOK_EVENT_REQUEST,
      payload: { id },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_WEBHOOK_EVENT_SUCCESS,
      payload: { id: response.webhook_event_id },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_WEBHOOK_EVENT_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
}

export default deleteWebhookEvent;