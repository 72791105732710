import getAllUsers from './getAllUsers';
import inviteUser from './inviteUser';
import getUserProfile from './getUserProfile';
import updateUserProfile from './updateUserProfile';
import uploadAvatar from './uploadAvatar';
import deleteUser from './deleteUser';
import updatePreferences from './updatePreferences';
import getUserPreferences from './getUserPreferences';
import fetchUsersForSelect from './fetchUsersForSelect';
import fetchUsersPermissions from './fetchUsersPermissions';
import updateUserPermissions from './updateUserPermissions';
import updateEmailRequest from './updateEmailRequest';
import updateEmailConfirm from './updateEmailConfirm';
import deleteAvatar from './deleteAvatar';
import fetchMyCompanyInvitations from './fetchMyCompanyInvitations';
import acceptCompanyInvitation from './acceptCompanyInvitation';
import declineCompanyInvitation from './declineCompanyInvitation';
import migrateUser from './migrateUser';
import undoMigrateUser from './undoMigrateUser';

export default {
  getAllUsers,
  inviteUser,
  getUserProfile,
  updateUserProfile,
  uploadAvatar,
  deleteAvatar,
  deleteUser,
  updatePreferences,
  getUserPreferences,
  fetchUsersForSelect,
  fetchUsersPermissions,
  updateUserPermissions,
  updateEmailRequest,
  updateEmailConfirm,
  fetchMyCompanyInvitations,
  acceptCompanyInvitation,
  declineCompanyInvitation,
  migrateUser,
  undoMigrateUser,
};
