import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const undoMigrateUser = callback => {
  return dispatch => {
    confirmDialog.warning({
      title: 'Reverter Migração',
      message:
        'Ao confirmar sua conta será revertida para o antigo sistema de assinaturas e será necessário realizar o login novamente. <br /><br /> Deseja continuar ?',
      confirmButtonText: 'Sim, reverter',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request());

        service
          .undoMigrateUser()
          .then(response => {
            dispatch(success(response));

            dispatch(alertActionCreators.success('Migração revertida com sucesso!'));

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.USER_UNDO_MIGRATION_REQUEST,
      payload: {},
    };
  }
  function success(response) {
    return {
      type: constants.USER_UNDO_MIGRATION_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.USER_UNDO_MIGRATION_FAILURE,
      payload: error,
    };
  }
};

export default undoMigrateUser;
