import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';
import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';
import FORMATTERS from 'helpers/formatters';

const payInvoice = (params, callback, errorCallback) => {
  return dispatch => {
    const message = `
    Ao confirmar, uma nova tentativa de pagamento será realizada: <br /><br />
    <strong>${FORMATTERS.NUMBER(params.amount)}</strong> com <strong class="text-uppercase">${params.payment_method_description}</strong>
    <br /> <br /> Confirmar pagamento?`;

    confirmDialog.info({
      title: 'Pagar fatura',
      html: message,
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      allowEscapeKey: false,
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Processando',
          html: 'Aguarde enquanto processamos a sua solicitação...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            delete params.payment_method_description;

            service
              .payInvoice(params)
              .then(response => {
                dispatch(success(response.data));

                Swal.close();

                if (callback) {
                  callback(response.data);
                }
              })
              .catch(error => {
                dispatch(failure(error));

                if (errorCallback) {
                  errorCallback(error);
                }
              });
          },
        });
      },
      onCancel: () => {
        errorCallback();
      },
    });
  };

  function request() {
    return {
      type: constants.PAY_INVOICE_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.PAY_INVOICE_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.PAY_INVOICE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default payInvoice;
