import { connect } from 'react-redux';

import webhooksActionCreators from '_store/_actions/webhooks';

import WebhookModal from './WebhookModal';

const mapStateToProps = state => ({
  isLoading: state.webhooks.isLoading,
});

const mapDispatchToProps = {
  onCreateWebhook: webhooksActionCreators.createWebhook,
  onUpdateWebhook: webhooksActionCreators.updateWebhook,
  onFetchWebhooks: webhooksActionCreators.getAllWebhooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookModal);
