import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const deletePaymentMethod = (id, callback) => {
  return dispatch => {
    dispatch(request());

    service
      .deletePaymentMethod(id)
      .then(response => {
        const { data } = response;

        dispatch(success({ id }));

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.DELETE_PAYMENT_METHOD_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_PAYMENT_METHOD_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_PAYMENT_METHOD_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deletePaymentMethod;
