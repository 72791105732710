import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/webhooks.constants';
import service from '../../../services/webhooks.service';

import alertActionCreators from '../alert.actions';

const deleteWebhook = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Remover Webhook',
      message: 'Tem certeza que deseja excluir este Webhook? Esta ação não poderá ser desfeita.',
      confirmButtonText: 'Sim, excluir',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteWebhook(id).then(
          (response) => {
            dispatch(success(response.data));
            dispatch(alertActionCreators.success('Webhook excluído com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.WEBHOOKS_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(webhook) {
    return {
      type: constants.WEBHOOKS_DELETE_SUCCESS,
      payload: webhook,
    };
  }
  function failure(error) {
    return {
      type: constants.WEBHOOKS_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteWebhook;