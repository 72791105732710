import { connect } from 'react-redux';

import integrationsActionsCreators from '_store/_actions/integrations';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import ProviderModal from './ProviderModal';

const mapStateToProps = state => ({
  isLoading: state.integrations.isLoading,
  accounts: state.companyTransactions.accounts,
  categories: state.companyTransactions.categories,
});

const mapDispatchToProps = {
  onCreateIntegration: integrationsActionsCreators.createIntegration,
  onUpdateIntegration: integrationsActionsCreators.updateIntegration,
  onFetchIntegrations: integrationsActionsCreators.getAllIntegrations,
  onFetchCategories: companyTransactionsActionCreators.fetchAllCategories,
  onFetchBankAccounts: companyTransactionsActionCreators.fetchAllAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderModal);
