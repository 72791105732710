import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Button, SimpleAlert } from '_components/_core';

function CompanyInvitations({
  isLoading,
  invitations,
  onFetchInvitations,
  onFetchCompanies,
  onAcceptInvitation,
  onDeclineInvitation,
}) {
  const [isFetching, setIsFetching] = useState(false);

  const handleAcceptInvitation = useCallback(
    invitation => {
      const params = {
        token: invitation.token,
      };

      setIsFetching(true);

      const successCallback = () => {
        setIsFetching(false);

        onFetchInvitations();
        onFetchCompanies();
      };

      const errorCallback = () => {
        setIsFetching(false);
      };

      onAcceptInvitation(params, successCallback, errorCallback);
    },
    [onAcceptInvitation, onFetchInvitations, onFetchCompanies],
  );

  const handleDeclineInvitation = useCallback(
    invitation => {
      setIsFetching(true);

      const successCallback = () => {
        onFetchInvitations();
      };

      const errorCallback = () => {
        setIsFetching(false);
      };

      onDeclineInvitation(invitation.id, successCallback, errorCallback);
    },
    [onDeclineInvitation, onFetchInvitations],
  );

  if (isEmpty(invitations)) {
    return null;
  }

  return (
    <div>
      {invitations.map(invitation => (
        <SimpleAlert key={invitation.key} variant="info">
          <h5>Convite recebido</h5>
          <p className="m-0 p-0">
            Você foi convidado para participar da empresa{' '}
            <strong>{invitation.company_name}</strong>
          </p>
          <Button
            onClick={() => handleAcceptInvitation(invitation)}
            variant="success-2"
            size="sm"
            className="mt-3"
            isLoading={isLoading || isFetching}
          >
            Aceitar
          </Button>
          <Button
            onClick={() => handleDeclineInvitation(invitation)}
            variant="inverse-danger"
            size="sm"
            className="mt-3 ml-2"
            isLoading={isLoading || isFetching}
          >
            Recusar
          </Button>
        </SimpleAlert>
      ))}
    </div>
  );
}

CompanyInvitations.defaultProps = {
  invitations: [],
  isLoading: false,
};

CompanyInvitations.propTypes = {
  invitations: PropTypes.array,
  isLoading: PropTypes.bool,
  onFetchInvitations: PropTypes.func.isRequired,
  onAcceptInvitation: PropTypes.func.isRequired,
  onDeclineInvitation: PropTypes.func.isRequired,
};
export default CompanyInvitations;
