import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/webhooks.constants';
import service from '../../../services/webhooks.service';

import alertActionCreators from '../alert.actions';

const resendWebhookEvent = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Reenviar Evento',
      message: 'Tem certeza que deseja reenviar este evento?',
      confirmButtonText: 'Sim, reenviar',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.resendWebhookEvent(id).then(
          (response) => {
            dispatch(success(response.data));
            dispatch(alertActionCreators.success('Evento reenviado com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
  });
};

  function request() {
    return {
      type: constants.RESEND_WEBHOOK_EVENT_REQUEST,
      payload: { },
    };
  }
  function success(webhook) {
    return {
      type: constants.RESEND_WEBHOOK_EVENT_SUCCESS,
      payload: webhook,
    };
  }
  function failure(error) {
    return {
      type: constants.RESEND_WEBHOOK_EVENT_FAILURE,
      payload: error,
    };
  }
}

export default resendWebhookEvent;