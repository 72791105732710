import * as yup from 'yup';

import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const RepeatInvoiceChargeSchema = yup.object().shape({
  invoice_id: yup.string().required('Obrigatório').nullable(),
  payment_method_id: yup.string().required('Obrigatório').nullable(),
});

export default RepeatInvoiceChargeSchema;
