import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { LoadingIcon } from '_components/_shared';
import {
  Overview,
  PaymentsHistory,
  AdditionalUsers,
  AdditionalCompanies,
  Checkout,
  CancelSubscription,
  InvoiceDetails,
  InvoiceHistory,
  ChangePlan,
  SubscriptionQueryAlert,
  ChangePaymentMethod,
} from './components';

function Subscription({
  isLoading,
  subscription,
  activeCompany,
  onFetchSubscription,
  onClearPaymentAlert,
  onFetchCompanies,
  onUpdateStoreSubscription,
  onFetchActiveCompany,
  onRefreshPaymentData,
}) {
  const [view, setView] = useState('DEFAULT');
  const [params, setParams] = useState({});

  useEffect(() => {
    if (view === 'DEFAULT') {
      onFetchSubscription();
    }
  }, [onFetchSubscription, activeCompany, view]);

  useEffect(() => {
    return () => {
      onClearPaymentAlert();
    };
  }, [onClearPaymentAlert, activeCompany]);

  useEffect(() => {
    setView('DEFAULT');
  }, [activeCompany]);

  const handleChangeView = useCallback((newView, params) => {
    setView(newView);
    setParams(params);
  }, []);

  const commonProps = useMemo(
    () => ({
      isLoading,
      subscription,
      onChangeView: handleChangeView,
      onFetchCompanies,
      onUpdateStoreSubscription,
      onFetchActiveCompany,
      onRefreshPaymentData,
      ...params,
    }),
    [
      isLoading,
      subscription,
      handleChangeView,
      params,
      onFetchCompanies,
      onUpdateStoreSubscription,
      onFetchActiveCompany,
      onRefreshPaymentData,
    ],
  );

  // if (
  //   !isLoading &&
  //   subscription &&
  //   ['trialing', 'trial-ended'].includes(subscription.status) &&
  //   !subscription.pending_invoice.id
  // ) {
  //   return (
  //     <Container>
  //       <Checkout {...commonProps} />
  //     </Container>
  //   );
  // }

  return (
    <Container>
      {isLoading && <LoadingIcon className="mt-4" text="Carregando..." />}
      <SubscriptionQueryAlert />
      {!isLoading && (
        <>
          {view === 'DEFAULT' && <Overview {...commonProps} />}
          {view === 'PAYMENTS_HISTORY' && <PaymentsHistory {...commonProps} />}
          {view === 'ADDITIONAL_USERS' && <AdditionalUsers {...commonProps} />}
          {view === 'ADDITIONAL_COMPANIES' && <AdditionalCompanies {...commonProps} />}
          {view === 'CANCEL_SUBSCRIPTION' && <CancelSubscription {...commonProps} />}
          {view === 'CHECKOUT' && <Checkout {...commonProps} />}
          {view === 'INVOICE_HISTORY' && <InvoiceHistory {...commonProps} />}
          {view === 'INVOICE_DETAILS' && <InvoiceDetails {...commonProps} />}
          {view === 'CHANGE_PLAN' && <ChangePlan {...commonProps} />}
          {view === 'CHANGE_PAYMENT_METHOD' && <ChangePaymentMethod {...commonProps} />}
        </>
      )}
    </Container>
  );
}

Subscription.defaultProps = {
  payments: [],
  isLoading: false,
  subscription: null,
  showPaymentAlert: false,
};

Subscription.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array,
  activeCompany: PropTypes.object,
  subscription: PropTypes.object,
  showPaymentAlert: PropTypes.bool,
  onFetchPayments: PropTypes.func,
  onFetchSubscription: PropTypes.func,
  onClearPaymentAlert: PropTypes.func,
};

export default Subscription;
