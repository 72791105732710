import styled, { css } from 'styled-components';

export const EventsContainer = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '40% 60%')};
`;

export const EventsList = styled.div`

`;

export const CustomHr = styled.div`
  border-bottom: 1px solid #F1F1F4;
`;

export const EventDate = styled.div`
  padding: 8px;
  font-weight: 500;
  color: #071437;
`;

export const EventContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

export const Counter = styled.p`
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

  span.counter-label {
    font-size: 13px;
    color: rgb(104,115,133);
  }
`;

export const Divider = styled.div`
  margin: 13px;
  border-right: 1px solid #F1F1F4;
  height: 100%;
  align-items: center;
`;

export const Event = styled.div`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F1F1F4;
  font-weight: 400;
  border-left: 3px solid transparent;

  &:hover {
    background-color: rgb(246,248,250);
    cursor: pointer;
  }

  ${({ selected }) =>
    selected &&
    css`
    background-color: rgb(246,248,250);
    border-left: 3px solid #007BFF;
  `}
`;

export const EventIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const EventDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const EventListName = styled.div`
  flex: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
`;

export const EventListDate = styled.div`
  flex: ${({ isMobile }) => (isMobile ? '0 0 40%' : 'auto')};
  text-align: ${({ isMobile }) => (isMobile ? 'right' : '')};
`;

export const EventDetail = styled.div`
  padding: 16px;
  border-left: 1px solid #F1F1F4;

  @media (max-width: 768px) {
    border-left: none;
    padding: 0px;
  }
`;

export const EventName = styled.h4`
  color: #071437;
`;

export const StatusCode = styled.div`
  font-weight: 400;
`;

export const EventResponse = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #F1F1F4;

  h6 {
    color: #071437;
  }

  &&& {
    .w-rjv-arrow {
      display: none;
    }
  }
`;

export const EventRequest = styled.div`
  margin-top: 16px;
  
  h6 {
    color: #071437;
  }

  &&& {
    .w-rjv-arrow {
      display: none;
    }
  }
`;
