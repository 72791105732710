import HttpService from './http.service';

export default {
  getAllPlans,
  createCheckoutSession,
  createAuthorizedCheckoutSession,
  getUserSubscriptionStatus,
  updatePlan,
  createUpdatePaymentSession,
  getCompanySubscription,
  getCompanySubscriptionPlans,
  updateBillingInformation,
  createCheckout,
  cancelSubscription,
  fetchSubscriptionInvoices,
  validatePlanChange,
  refreshPaymentData,
  fetchManagerSubscription,
  fetchUserSeatsPricing,
  confirmUserSeatsPurchase,
  fetchCompanySeatsPricing,
  confirmCompanySeatsPurchase,
  getUpdatePaymentSessionUrl,
  fetchSubscriptionInvoice,
  fetchPaymentMethods,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  createPaymentMethod,
  confirmPlanChange,
  payInvoice,
};

async function getAllPlans() {
  return HttpService.get('/subscription/plans');
}

async function createCheckoutSession(params) {
  return HttpService.post('/checkout-session', params);
}

async function createAuthorizedCheckoutSession(params) {
  return HttpService.post('/authorized-checkout-session', params);
}

async function getUserSubscriptionStatus() {
  return HttpService.get('/subscription');
}

async function updatePlan(params) {
  return HttpService.put('/subscription', params);
}

async function createUpdatePaymentSession() {
  return HttpService.post('/update-payment-session');
}

async function getCompanySubscription() {
  return HttpService.get('/company-subscription');
}

async function getCompanySubscriptionPlans() {
  return HttpService.get('/company-subscription-plans');
}

async function updateBillingInformation(params) {
  return HttpService.put('/billing-information', params);
}

async function createCheckout(params) {
  return HttpService.post('/create-checkout', params);
}

async function cancelSubscription(params) {
  return HttpService.delete('/cancel-subscription', params);
}

async function fetchSubscriptionInvoices(params) {
  return HttpService.get('/subscription-invoices', params);
}

async function fetchSubscriptionInvoice(invoiceId) {
  return HttpService.get(`/subscription-invoices/${invoiceId}`);
}

async function fetchUserSeatsPricing(params) {
  return HttpService.get('/user-seats-pricing', params);
}

async function validatePlanChange(params) {
  return HttpService.get('/validate-plan-change', params);
}

async function refreshPaymentData(params) {
  return HttpService.post('/refresh-payment', params);
}

async function confirmUserSeatsPurchase(params) {
  return HttpService.post('/user-seats-purchase', params);
}

async function fetchManagerSubscription() {
  return HttpService.get('/manager-subscription');
}

async function fetchCompanySeatsPricing(params) {
  return HttpService.get('/company-seats-pricing', params);
}

async function confirmCompanySeatsPurchase(params) {
  return HttpService.post('/company-seats-purchase', params);
}

async function getUpdatePaymentSessionUrl() {
  return HttpService.get('/update-payment-session-url');
}

async function fetchPaymentMethods() {
  return HttpService.get('/payment-methods');
}

async function deletePaymentMethod(id) {
  return HttpService.delete(`/payment-methods/${id}`);
}

async function setDefaultPaymentMethod(id) {
  return HttpService.put(`/payment-methods/${id}/set-default`);
}

async function createPaymentMethod(params) {
  return HttpService.post('/payment-methods', params);
}

async function confirmPlanChange(params) {
  return HttpService.post('/confirm-plan-change', params);
}

async function payInvoice(params) {
  return HttpService.post('/pay-invoice', params);
}
