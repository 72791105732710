import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

import { MaskedInput } from '_components/_core';

function FormCpfCnpjField({ placeholder, type, disabled, ...props }) {
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Form.Control
        as={MaskedInput}
        type="tel"
        maskType={type}
        placeholder={placeholder}
        {...field}
        disabled={disabled}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormCpfCnpjField.defaultProps = {
  type: 'CPF',
};

FormCpfCnpjField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default FormCpfCnpjField;
