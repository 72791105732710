import styled, { css } from 'styled-components';
import DropdownItem from 'react-bootstrap/DropdownItem';

import Modal from '_components/_core/Modal';

import { Button } from '_components/_core';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    padding: 24px !important;
    max-height: 60vh;
    overflow: auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Option = styled(DropdownItem)`
  font-size: 14px;
  font-weight: 500;
  padding: 0;

  :hover, :focus {
    color: #00784e;
    background-color: transparent;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.5rem;

  :hover, :focus {
    text-decoration: none;
    color: var(--text-primary);
    background-color: transparent;
  }

`;

export const ActiveCompany = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px;

  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    font-size: 12px;
    color: rgb(101, 111, 125);
  }
`;

export const CompanyAvatar = styled.img`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  margin-right: 8px;

  ${({ size }) =>
    size === 'small' &&
    css`
    width: 20px;
    height: 20px;
  `}
`;

export const CustomTabs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 32px;
  list-style: none;
  font-weight: 600;
  font-size: 14.95px;

 
`;

export const CustomTab = styled.li`

    display: flex;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;

    padding: .2rem 0;
    margin: 0 1rem;
    color: #99A1B7;

    /* margin-bottom: 2rem; */
    padding-top: 1.25rem !important;
    padding-bottom: 0.5rem !important;
    border-bottom-width: 2px;
    border-bottom: 2px solid transparent;
    transition: color .2s ease;

    &:hover {
      cursor: pointer;
      color: #00784e;
    }

    ${({ isActive }) =>
      isActive &&
      css`
      color: #00784e;
      border-bottom: 2px solid #00784e !important;
    `}
  
`;

export const SimpleAlert = styled.div`
  padding: 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 16px;
  font-weight: 400;

  ul {
    margin: 0;
  }
  
  ${({ variant }) =>
    variant === 'danger' &&
    css`
    background-color: #f8d7da;
    color: #721c24;
  `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
    background-color: #EEFBF2;
    color: #00784e;
    border-left: 2px solid #00784e;
  `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
    background-color: #fff3cd;
    color: #856404;
  `}

  ${({ variant }) =>
    variant === 'info' &&
    css`
    background-color: #F0FAFC;
    color: #0C5460;
    border-left: 3px solid #63B3ED;
  `}
`;

export const SubscriptionSelectContainer = styled.div`
  padding: 16px 24px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  background-color: #F9FAFC;
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }

  strong {
    font-size: 16px;
    font-weight: 600;
    color: #071437;
  }

  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  h4 {
    margin-bottom: 3px;
  }
`;

export const RequeridedIndicator = styled.span`
  color: #ff0000;
  font-weight: 600;
  margin-left: 4px;
`;
