import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';

import { Button, FormSelectField, FloatingCard } from '_components/_core';

import { ModalFooter } from './styles';
import AsaasFields from './PaymentGatewayFields/AsaasFields';

const ApiKeysModal = ({
  isVisible,
  isLoading,
  provider,
  onCreateIntegration,
  onUpdateIntegration,
  onFetchIntegrations,
  onModalToggle,
  onFetchCategories,
  onFetchBankAccounts,
  categories,
  accounts,
}) => {
  const PROVIDERS = [{ value: 'ASAAS', label: 'ASAAS' }];

  const commonProps = useMemo(() => {
    return {
      categories,
      accounts,
    };
  }, [categories, accounts]);

  const renderProviderFields = (values, setFieldValue) => {
    const allProps = {
      ...commonProps,
      provider_settings: values.provider_settings,
      values,
      setFieldValue,
    };

    switch (values.provider_name) {
      case 'ASAAS':
        return <AsaasFields {...allProps} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    onFetchCategories();
    onFetchBankAccounts();
  }, [onFetchCategories, onFetchBankAccounts]);

  const handleCreateUpdateIntegration = useCallback(
    (values, { resetForm }) => {
      if (provider) {
        onUpdateIntegration(provider._id, values, () => {
          onModalToggle();
          resetForm();

          onFetchIntegrations();
        });
      } else {
        onCreateIntegration(values, () => {
          onModalToggle();
          resetForm();

          onFetchIntegrations();
        });
      }
    },
    [
      onFetchIntegrations,
      onCreateIntegration,
      onUpdateIntegration,
      onModalToggle,
      provider,
    ],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onModalToggle}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          Salvar
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading],
  );

  return (
    <Formik
      initialValues={{
        provider_name: provider ? provider.provider_name : null,
        provider_settings: provider ? provider.provider_settings : null,
        enabled: provider ? provider.enabled : false,
      }}
      onSubmit={handleCreateUpdateIntegration}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid, setFieldValue }) => (
        <>
          <FloatingCard
            title={provider ? 'Editar Integração' : 'Criar Integração'}
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onModalToggle}
            footerContent={renderModalFooter(handleSubmit, isValid)}
            withCloseButton
            width={650}
            bodyClassName="p-3"
          >
            <Form>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>Provedor</Form.Label>
                  <FormSelectField
                    name="provider_name"
                    placeholder="Selecione um provedor"
                    options={PROVIDERS}
                    isSearchable={false}
                  />
                </Form.Group>
              </Form.Row>
              {values.provider_name && renderProviderFields(values, setFieldValue)}
            </Form>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
};

export default ApiKeysModal;

ApiKeysModal.defaultProps = {
  isVisible: false,
  isLoading: false,
};

ApiKeysModal.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  provider: PropTypes.object,
  onCreateIntegration: PropTypes.func.isRequired,
  onUpdateIntegration: PropTypes.func.isRequired,
  onFetchIntegrations: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
};
