import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';

import { Button, FormDateField, FormTextField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const ApiKeysModal = ({
  isVisible,
  isLoading,
  apiKey,
  onCreateKey,
  onUpdateKey,
  onFetchKeys,
  onModalToggle,
}) => {
  const handleCreateUpdateKey = useCallback(
    (values, { resetForm }) => {
      if (values._id) {
        onUpdateKey(values, () => {
          onModalToggle();
          resetForm();

          onFetchKeys();
        });
      } else {
        onCreateKey(values, () => {
          onModalToggle();
          resetForm();

          onFetchKeys();
        });
      }
    },
    [onFetchKeys, onCreateKey, onUpdateKey, onModalToggle],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid, values) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onModalToggle}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          {values._id ? 'Atualizar' : 'Criar'}
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        _id: apiKey ? apiKey._id : null,
        name: apiKey ? apiKey.name : null,
        description: apiKey ? apiKey.description : null,
        expire_at: apiKey ? apiKey.expire_at : null,
      }}
      onSubmit={handleCreateUpdateKey}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values }) => (
        <>
          <StyledModal
            title={apiKey ? 'Editar chave de API' : 'Criar chave de API'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Nome</Form.Label>
                  <FormTextField name="name" placeholder="Nome" type="name" required />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data de expiração</Form.Label>
                  <FormDateField name="expire_at" placeholder="Data de expiração" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descrição</Form.Label>
                  <FormTextField
                    name="description"
                    placeholder="Descrição"
                    type="text"
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default ApiKeysModal;

ApiKeysModal.defaultProps = {
  isVisible: false,
  isLoading: false,
};

ApiKeysModal.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  apiKey: PropTypes.object,
  onCreateKey: PropTypes.func,
  onUpdateKey: PropTypes.func.isRequired,
  onFetchKeys: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
};
