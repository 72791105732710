import styled from 'styled-components';

export const StyledTable = styled.table`
  &&& {
    font-size: 14px;
    width: 100%;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: 1rem .75rem;
      vertical-align: middle;
      border-top: 1px solid #e7eaef;
      font-weight: 400;
      cursor: pointer;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;
