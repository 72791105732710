import styled, { css } from 'styled-components';

export const MenuContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const MenuItemsContainer = styled.div`
  ${({ section }) =>
    section === 'Main' &&
    css`
      margin-top: 12px;  
  `}
  
  ${({ section }) =>
    section === 'User' &&
    css`
      margin-top: 12px;  
  `}
  
`;

export const MenuWrapper = styled.div`
  padding: 16px;
`;

export const IconWrapper = styled.div`
  background-color: #f5f6f8;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #675dff;
  }

  ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  transition: background-color 300ms cubic-bezier(0, 0, 0.12, 0.95);

  &:first-child {
    border-bottom: none;
    border-top: none;
  }

  &:hover {
    background-color: #f5f6f8;
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f5f6f8;
      font-weight: 500 !important;
      border-right: 2px solid #2ecc71;    
  `}  
`;

export const SectionTitle = styled.h3`
  color: #393A3D;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 32px;
  display: block;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1px;
  margin-left: 16px;
`;

export const ItemTitle = styled.span`
  color: #030507;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
`;

export const ItemDescription = styled.p`
  color: #b1b1b1;
  font-size: 11px;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const Footer = styled.div`
  color: #393A3D;
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;

  svg {
    padding: 0;
    margin: 0;
  }
`;
