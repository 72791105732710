import constants from '../../_constants/webhooks.constants';
import service from '../../../services/webhooks.service';

import alertActionCreators from '../alert.actions';

const createWebhook = (values, callback) => {
  return (dispatch) => {
    dispatch(request(values));

    service.createWebhook(values).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(alertActionCreators.success('Webhook criado com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.WEBHOOKS_CREATE_REQUEST,
      payload: { },
    };
  }
  function success(webhook) {
    return {
      type: constants.WEBHOOKS_CREATE_SUCCESS,
      payload: webhook,
    };
  }
  function failure(error) {
    return {
      type: constants.WEBHOOKS_CREATE_FAILURE,
      payload: error,
    };
  }
};

export default createWebhook;