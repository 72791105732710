import React, { createContext, useContext, useRef } from 'react';

import useWebsocket from 'helpers/useWebsocket';
import { WS_NAMESPACES } from 'helpers/constants';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const subscriptionWebSocket = useWebsocket(WS_NAMESPACES.SUBSCRIPTION_EVENTS);
  const userWebSocket = useWebsocket(WS_NAMESPACES.USER_EVENTS);

  const websockets = useRef({
    subscriptionWebSocket,
    userWebSocket,
  });

  return (
    <WebSocketContext.Provider value={websockets.current}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};
