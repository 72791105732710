export const WEBHOOK_EVENTS = [
  'transaction.created',
  'transaction.updated',
  'transaction.deleted',
  'contact.created',
  'contact.updated',
  'contact.deleted',
  'bank_account.created',
  'bank_account.updated',
  'bank_account.deleted',
  'tag.created',
  'tag.updated',
  'tag.deleted',
  'cost_center.created',
  'cost_center.updated',
  'cost_center.deleted',
  'category.created',
  'category.updated',
  'category.deleted',
  'transfer.created',
  'transfer.updated',
  'transfer.deleted',
];