import { connect } from 'react-redux';

import integrationsActionsCreators from '_store/_actions/integrations';

import Integrations from './Integrations';

const mapStateToProps = state => ({
  bankIntegrationSettings: state.integrations.bankIntegrationSettings,
  isLoading: state.integrations.isLoading,
});

const mapDispatchToProps = {
  onFetchIntegrations: integrationsActionsCreators.getAllIntegrations,
  onFetchIntegrationByType: integrationsActionsCreators.getIntegrationByType,
  onDeleteProvider: integrationsActionsCreators.deleteIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
