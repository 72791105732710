import styled from 'styled-components';

import Modal from '_components/_core/Modal';
import { Form } from 'react-bootstrap';

export const StyledTable = styled.table`
  &&& {
    font-size: 14px;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: .75rem 1rem;
      vertical-align: middle;
      /* border-top: 1px solid #e7eaef; */
      font-weight: 400;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const StyledModal = styled(Modal)`
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchContainer = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F5FA;

  label {
    font-weight: 600;
  }
`;

export const RequiredIndicator = styled.span`
  color: #ff0000;
  font-weight: 600;
  margin-left: 4px;
`;

export const StyledFormCheck = styled(Form.Check)`
  .form-check-input {
    margin-top: 0.1rem !important;
    width: 17px !important;
    height: 17px !important;

    &:hover {
      cursor: pointer !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      background: transparent;
      z-index: 0;
    }
  }
`;
