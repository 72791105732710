import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import AddPaymentMethodButton from './AddPaymentMethodButton';

const mapStateToProps = state => ({
  isLoading: state.subscription.isLoading,
  paymentMethods: state.subscription.paymentMethods,
});

const mapDispatchToProps = {
  onGetUpdatePaymentSessionUrl: subscriptionActionCreators.getUpdatePaymentSessionUrl,
  onCreatePaymentMethod: subscriptionActionCreators.createPaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodButton);
