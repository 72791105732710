import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { isEmpty, sortBy } from 'lodash';
import { LoadingIcon } from '_components/_shared';
import { LuArrowRight } from 'react-icons/lu';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';
import { Button, HintIcon, SimpleAlert } from '_components/_core';
import { Plan } from '_components/_shared';

import {
  CustomBreadcrumb,
  CustomBreadcrumbItem,
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../styles';

import {
  CurrentPlan,
  Details,
  PlanItem,
  PlansContainer,
  RadioContainer,
  SmallHeader,
} from './styles';

const CYCLE = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function ChangePlan({
  activeCompany,
  subscription,
  onFetchPlans,
  onChangeView,
  plans,
  onValidatePlanChange,
  onConfirmPlanChange,
}) {
  useEffect(() => {
    onFetchPlans();
  }, [onFetchPlans, activeCompany]);

  const [selectedPlan, setSelectedPlan] = useState(subscription.plan.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false);
  const [overview, setOverview] = useState(null);

  const [changingTimeout, setChangingTimeout] = useState(0);

  const validPlans = useMemo(() => {
    if (!subscription || isEmpty(plans)) {
      return [];
    }

    const { cycle_months } = subscription.plan;

    return sortBy(
      plans.filter(
        plan => plan.cycle_months === cycle_months && plan.id !== subscription.plan.id,
      ),
      'price',
    );
  }, [subscription, plans]);

  const delayedHandlePlanSelected = planId => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      setIsLoading(true);

      const params = {
        new_plan_id: planId,
      };

      onValidatePlanChange(params, response => {
        setOverview(response);

        setIsLoading(false);
      });
    }, 700);

    setChangingTimeout(newChangingTimeout);
  };

  const handlePlanSelected = planId => {
    setSelectedPlan(planId);

    delayedHandlePlanSelected(planId);
  };

  const handleConfirmChange = useCallback(() => {
    setIsSwitchingPlan(true);

    const plan = plans.find(p => p.id === selectedPlan);
    const params = {
      new_plan_id: selectedPlan,
      new_plan_name: plan.name,
      amount_due: overview.amount_due,
    };

    const errorCallback = () => {
      setIsSwitchingPlan(false);
    };

    onConfirmPlanChange(
      params,
      () => {
        setIsSwitchingPlan(false);

        onChangeView('DEFAULT');
      },
      errorCallback,
    );
  }, [onConfirmPlanChange, onChangeView, selectedPlan, overview, plans]);

  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
    className,
  }) => (
    <>
      <Value
        className={classNames({
          'w-100 d-flex justify-content-between align-items-center': true,
          [className]: className,
        })}
      >
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  if (!['active', 'trialing'].includes(subscription.status)) {
    return (
      <>
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
        </CustomBreadcrumb>
        <SimpleAlert variant="info">
          Só é possível alterar o plano após o período de teste.
        </SimpleAlert>
      </>
    );
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <SimpleAlert variant="info" className="mt-3">
        <p className="m-0">
          Só é possível alterar o plano para outro com o mesmo ciclo de cobrança.
          <br />
          Se desejar mudar o ciclo, cancele sua assinatura atual e faça uma nova
          assinatura.
        </p>
      </SimpleAlert>
      <CustomCard>
        <CustomCardBody>
          <Row>
            <Col>
              <SmallHeader>Plano em uso:</SmallHeader>
              <PlansContainer className="mt-3">
                <PlanItem className="mb-0">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{
                      flex: 1,
                    }}
                  >
                    <div>
                      <Label>{subscription.plan.name}</Label>
                      <Value variant="large">
                        {FORMATTERS.NUMBER(subscription.plan.price)}&nbsp;
                        <small>{CYCLE[subscription.plan.cycle_months]}</small>
                      </Value>
                    </div>
                    <div className="ml-4">
                      <CurrentPlan>
                        <Badge variant="warning">Plano atual</Badge>
                      </CurrentPlan>
                    </div>
                    <RadioContainer className="ml-auto">
                      <input type="radio" name="current_plan" disabled checked />
                    </RadioContainer>
                  </div>
                </PlanItem>
              </PlansContainer>
              <hr />
              <SmallHeader>Planos disponíveis:</SmallHeader>
              <PlansContainer className="mt-3">
                {validPlans.map(plan => (
                  <Plan
                    key={plan.id}
                    plan={plan}
                    isActive={plan.id === selectedPlan}
                    onSelectPlan={() => handlePlanSelected(plan.id)}
                    onChoosePlan={() => handlePlanSelected(plan.id)}
                    allPlans={plans}
                  />
                ))}
              </PlansContainer>
            </Col>
          </Row>
        </CustomCardBody>
      </CustomCard>
      {(overview || isLoading) && (
        <Row>
          <Col md={12}>
            <CustomCard className="mt-3">
              <CustomCardBody className="pb-0">
                <InfoItem
                  title="Resumo e confirmação"
                  contentStyle={{ flex: 1, width: '100%' }}
                  content={
                    <>
                      {isLoading && <LoadingIcon className="mt-4 mb-4" />}
                      {!isLoading && overview && (
                        <>
                          {!isEmpty(overview.warnings) && (
                            <SimpleAlert variant="warning">
                              <h5>Não é possível trocar para este plano</h5>
                              <ul>
                                {overview.warnings.map((warning, index) => (
                                  <li key={index}>{warning}</li>
                                ))}
                              </ul>
                            </SimpleAlert>
                          )}
                          <div className="ml-auto mr-auto">
                            <Details className="mt-4">
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {overview.old_plan.name}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">{overview.new_plan.name}</p>
                                </span>
                              </li>
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {overview.old_plan.max_transactions_per_month}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">
                                    {overview.new_plan.max_transactions_per_month}{' '}
                                    transações por mês
                                  </p>
                                </span>
                              </li>
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {overview.old_plan.max_users}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">
                                    {overview.new_plan.max_users} usuários
                                  </p>
                                </span>
                              </li>
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {`${overview.old_plan.max_storage_size_megabytes / 1000} GB`}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">
                                    {`${overview.new_plan.max_storage_size_megabytes / 1000} GB`}{' '}
                                    armazenamento
                                  </p>
                                </span>
                              </li>
                              {overview.can_change && (
                                <li>
                                  <span className="d-flex justify-content-center align-items-center">
                                    <p className="previous-value">
                                      {FORMATTERS.NUMBER(subscription.total_amount)}/
                                      {CYCLE[subscription.plan.cycle_months]}
                                    </p>
                                    <LuArrowRight size={14} className="mx-2" />
                                    <p className="highlight">
                                      {FORMATTERS.NUMBER(
                                        overview.new_subscription_amount,
                                      )}
                                      /{CYCLE[overview.new_plan.cycle_months]}
                                    </p>
                                  </span>
                                </li>
                              )}
                              {overview.amount_due > 0 && (
                                <li
                                  style={{
                                    borderTop: '1px solid #dee2e6',
                                    paddingTop: '0.5rem',
                                  }}
                                >
                                  <span className="d-flex justify-content-center align-items-center">
                                    <p className="previous-value no-strike text-warning">
                                      Valor à pagar hoje:
                                    </p>
                                    <LuArrowRight
                                      size={14}
                                      className="mx-2 text-warning"
                                    />
                                    <p className="highlight text-warning">
                                      {FORMATTERS.NUMBER(overview.amount_due)}{' '}
                                      (proporcional)
                                    </p>
                                    <HintIcon
                                      hint="Este é o valor da diferença entre seu plano atual e o novo plano escolhido, calculado com base nos dias restantes até a próxima renovação."
                                      className="d-flex"
                                    />
                                  </span>
                                </li>
                              )}
                            </Details>
                          </div>
                        </>
                      )}
                    </>
                  }
                />
              </CustomCardBody>
              {!isLoading && (
                <CustomCardFooter className="d-flex justify-content-center align-items-center pt-3 pb-3">
                  {overview && (
                    <Button
                      disabled={!overview.can_change || isLoading || isSwitchingPlan}
                      variant="success-2"
                      isLoading={isSwitchingPlan}
                      onClick={handleConfirmChange}
                    >
                      Confirmar alteração de plano
                    </Button>
                  )}
                </CustomCardFooter>
              )}
            </CustomCard>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ChangePlan;
