import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  update,
  remove,
  invite,
  getCurrentProfile,
  updateUserProfile,
  getAvatarSignedUrl,
  updateUserAvatar,
  deleteUserAvatar,
  deleteUser,
  updateUserPreferences,
  getUserPreferences,
  getUsersForSelect,
  fetchUserPermissions,
  updateUserPermissions,
  updateEmailRequest,
  updateEmailConfirm,
  fetchMyCompanyInvitations,
  acceptInvitation,
  declineInvitation,
  migrateUser,
  undoMigrateUser,
};

async function getAll(params) {
  return HttpService.get('/users', params);
}

async function getUsersForSelect() {
  return HttpService.get('/users-dropdown');
}

async function getById(id) {
  return HttpService.get(`/users/${id}`);
}

async function insert(user) {
  return HttpService.get('/users', user);
}

async function update(id, user) {
  return HttpService.put(`/users/${id}`, user);
}

async function invite(email) {
  return HttpService.post('/company-invitations', email);
}

async function remove(id) {
  return HttpService.delete(`/users/${id}`);
}

async function getCurrentProfile() {
  return HttpService.get('/user-profile');
}

async function updateUserProfile(user) {
  return HttpService.put('/user-profile', user);
}

async function getAvatarSignedUrl(file_name, file_type) {
  const body = {
    file_name,
    file_type,
  };

  return HttpService.post('/upload-avatar', body);
}

async function updateUserAvatar(final_url) {
  const body = {
    avatar_url: final_url,
  };

  return HttpService.put('/upload-avatar', body);
}

async function deleteUserAvatar(user_id) {
  return HttpService.delete(`delete-avatar/${user_id}/avatar`);
}

async function deleteUser(id) {
  return HttpService.delete(`/users/${id}`);
}

async function updateUserPreferences(options) {
  return HttpService.put('/user-preferences', options);
}

async function getUserPreferences() {
  return HttpService.get('/user-preferences');
}

async function fetchUserPermissions(id) {
  return HttpService.get(`/user-permissions/${id}`);
}

async function updateUserPermissions(user_id, permissions) {
  const body = {
    user_id,
    permissions,
  };

  return HttpService.put(`/user-permissions/${user_id}`, body);
}

async function updateEmailRequest(data) {
  return HttpService.post('/update-email-request', data);
}

async function updateEmailConfirm(data) {
  return HttpService.put('/update-email-confirm', data);
}

async function fetchMyCompanyInvitations() {
  return HttpService.get('/my-invitations');
}

async function acceptInvitation(params) {
  return HttpService.post('/my-invitations', params);
}

async function declineInvitation(id) {
  return HttpService.delete(`/my-invitations/${id}`);
}

async function migrateUser() {
  return HttpService.post('/subscription-migration');
}

async function undoMigrateUser() {
  return HttpService.delete('/subscription-migration');
}
