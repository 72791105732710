import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';

import {
  Button,
  FormSelectField,
  FormSwitchField,
  FormTextField,
  Tag,
} from '_components/_core';

import { WEBHOOK_EVENTS } from './utilities/constants.js';
import ValidationSchema from './utilities/ValidationSchema';

import { StyledModal, ModalFooter } from './styles';

const WebhookModal = ({
  isVisible,
  isLoading,
  webhook,
  onCreateWebhook,
  onUpdateWebhook,
  onFetchWebhooks,
  onModalToggle,
}) => {
  const handleCreateUpdateWebhook = useCallback(
    (values, { resetForm }) => {
      if (values.id) {
        onUpdateWebhook(values.id, values, () => {
          onModalToggle();
          resetForm();

          onFetchWebhooks();
        });
      } else {
        onCreateWebhook(values, () => {
          onModalToggle();
          resetForm();

          onFetchWebhooks();
        });
      }
    },
    [onFetchWebhooks, onCreateWebhook, onUpdateWebhook, onModalToggle],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid, values) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onModalToggle}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          {values.id ? 'Atualizar' : 'Criar'}
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        id: webhook ? webhook.id : null,
        name: webhook ? webhook.name : '',
        url: webhook ? webhook.url : '',
        events: webhook ? webhook.events : [],
        email: webhook ? webhook.email : '',
        enabled: webhook ? webhook.enabled : true,
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleCreateUpdateWebhook}
      enableReinitialize
    >
      {({ handleSubmit, values, setFieldValue, isValid }) => (
        <>
          <StyledModal
            title={webhook ? 'Editar Webhook' : 'Criar Webhook'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid, values)}
          >
            <Form>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>Nome</Form.Label>
                  <FormTextField name="name" placeholder="Nome do Webhook" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>Endereço</Form.Label>
                  <FormTextField name="url" placeholder="URL do Webhook" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>Eventos</Form.Label>
                  <FormSelectField
                    name="events"
                    options={WEBHOOK_EVENTS.map(event => ({
                      value: event,
                      label: event,
                    }))}
                    isMulti
                    value={values.events.map(event => ({ value: event, label: event }))}
                    onChange={selectedOptions => {
                      const selectedEvents = selectedOptions.map(option => option.value);

                      setFieldValue('events', selectedEvents);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>Status</Form.Label>
                  <div className="d-flex justify-content-start align-items-center">
                    <FormSwitchField name="enabled" />
                    {values.enabled && (
                      <Tag className="ml-3" variant="success">
                        Ativado
                      </Tag>
                    )}
                    {!values.enabled && (
                      <Tag className="ml-3" variant="danger">
                        Desativado
                      </Tag>
                    )}
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

WebhookModal.defaultProps = {
  isVisible: false,
  isLoading: false,
};

WebhookModal.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  webhook: PropTypes.object,
  onCreateWebhook: PropTypes.func.isRequired,
  onUpdateWebhook: PropTypes.func.isRequired,
  onFetchWebhooks: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
};

export default WebhookModal;
