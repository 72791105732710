import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Popover } from 'react-bootstrap';
import { LuSettings } from 'react-icons/lu';
import { FcSettings } from 'react-icons/fc';
import { IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';

import {
  CustomButton,
  CustomFilterButton,
  MenuEntry,
  StyledMenuText,
  StyledOverlayTrigger,
  StyledPopover,
} from './styles';

const SettingsMenu = ({ onLogout, variant }) => {
  const history = useHistory();

  const handleNavigate = useCallback(() => {
    history.push('/configuracoes');
  }, [history]);

  const popover = useCallback(
    () => (
      <StyledPopover>
        <Popover.Content>
          <MenuEntry
            onClick={() => {
              history.push('/configuracoes');

              document.body.click();
            }}
          >
            <LuSettings size="1rem" />
            <StyledMenuText>Configurações</StyledMenuText>
          </MenuEntry>
          <hr
            style={{
              padding: 0,
              margin: 0,
            }}
            className="mb-2 mt-2"
          />
          <MenuEntry onClick={onLogout}>
            <IoLogOutOutline size="1rem" />
            <StyledMenuText>Sair</StyledMenuText>
          </MenuEntry>
        </Popover.Content>
      </StyledPopover>
    ),
    [history, onLogout],
  );

  return (
    <>
      {variant === 'navbar' && (
        <StyledOverlayTrigger
          trigger="click"
          placement="bottom-end"
          overlay={popover()}
          rootClose
        >
          <CustomFilterButton variant="link">
            <IoSettingsOutline size="1.3em" />
          </CustomFilterButton>
        </StyledOverlayTrigger>
      )}
      {variant === 'sidebar' && (
        <CustomButton variant="link" onClick={handleNavigate}>
          <span>
            <div>
              <FcSettings size="1.5em" />
            </div>
            <p>Configurações</p>
          </span>
        </CustomButton>
      )}
    </>
  );
};

export default SettingsMenu;

SettingsMenu.defaultProps = {
  id: null,
  className: null,
  isMobile: false,
  variant: 'navbar',
  user: null,
  onOpenStateChange: () => {},
  isMigrated: false,
};

SettingsMenu.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['navbar', 'sidebar']),
  user: PropTypes.object,
  onOpenStateChange: PropTypes.func,
  name: PropTypes.string,
  isMigrated: PropTypes.bool,
};
