import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import DeleteFinancialData from './DeleteFinancialData';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  financialData: state.companyTransactions.financialData,
  isLoading: state.companyTransactions.isLoading,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onDeleteFinancialData: companyTransactionsActionCreators.deleteFinancialData,
  onFetchFinancialDataForDeletion:
    companyTransactionsActionCreators.getFinancialDataForDeletion,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFinancialData);
