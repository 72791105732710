import Swal from 'sweetalert2';
import has from 'lodash/has';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const confirmPlanChange = (params, callback, errorCallback) => {
  return dispatch => {
    let message = '';

    if (has(params, 'amount_due') && params.amount_due > 0) {
      message = `
      Ao alterar para um plano superior, será gerada uma fatura no valor proporcional de <strong>${FORMATTERS.NUMBER(params.amount_due)}</strong>.
      Durante este período, sua assinatura será temporariamente marcada como <strong>Vencida</strong> até que o pagamento seja confirmado. <br /> <br />
      <strong>Importante:</strong> Caso o pagamento não seja realizado a assinatura será <strong>cancelada automaticamente</strong> devido à regras do sistema parceiro de pagamentos. <br /> <br />
      Deseja prosseguir e gerar a fatura adicional?`;
    } else {
      message = `
      Após a confirmação, os recursos do seu plano atual deixarão de estar disponíveis.<br /><br />
      Deseja prosseguir e mudar para o plano <strong>${params.new_plan_name}</strong>?`;
    }

    confirmDialog.warning({
      title: 'Alteração de plano',
      html: message,
      confirmButtonText: 'Sim, alterar!',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        style: 'width: 80%;',
      },
      dangerMode: true,
      inputPlaceholder: 'Digite "alterar plano" sem aspas',
      preConfirm: inputValue => {
        if (inputValue !== 'alterar plano') {
          Swal.showValidationMessage('Digite "alterar plano" sem aspas.');
        }
      },
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Alterando plano...',
          html: 'Aguarde enquanto alteramos o plano da assinatura.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service
              .confirmPlanChange(params)
              .then(response => {
                dispatch(success(response.data));

                Swal.close();

                if (callback) {
                  callback(response.data);
                }
              })
              .catch(error => {
                dispatch(failure(error));

                if (errorCallback) {
                  errorCallback(error);
                }
              });
          },
        });
      },
      onCancel: () => {
        errorCallback();
      },
    });
  };

  function request() {
    return {
      type: constants.CONFIRM_PLAN_CHANGE_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CONFIRM_PLAN_CHANGE_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CONFIRM_PLAN_CHANGE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default confirmPlanChange;
