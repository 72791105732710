import React, {} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';

import { CustomCard, CustomCardBody } from '../../../../../styles';
import { Badge, Text } from '../styles';

function InvoiceHeader({ invoice }) {
  let message = '';

  switch (invoice.status) {
    case 'draft':
      message = `Rascunho`;
      break;
    case 'open':
      message = 'Aguardando Pagamento';
      break;
    case 'overdue':
      message = `Vencida`;
      break;
    case 'paid':
      message = `Pagamento realizado em ${FORMATTERS.DATE_DDMMYYYY(invoice.paid_at)}`;
      break;
    case 'void':
      message = `Anulada`;
      break;
    case 'uncollectible':
      message = `Inválida`;
      break;
    default:
      break;
  }

  // const hasInvoiceUrl = useMemo(() => !!invoice.provider_invoice_url, [invoice]);

  // const handleViewInvoice = useCallback(() => {
  //   const { provider_invoice_url } = invoice;

  //   window.open(provider_invoice_url, '_blank').focus();
  // }, [invoice]);

  return (
    <Row className="mt-3">
      <Col>
        <CustomCard>
          <CustomCardBody className="d-flex align-items-center">
            <span className="d-flex align-items-center">
              <Badge status={invoice.status} />
              <Text className="m-0 ml-3">{message}</Text>
            </span>
            {/* {hasInvoiceUrl && (
              <Button
                variant="default"
                className="d-flex justify-content-center align-items-center ml-auto"
                onClick={handleViewInvoice}
              >
                Ver fatura completa <LuArrowUpRight className="ml-2" />
              </Button>
            )} */}
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

InvoiceHeader.defaultProps = {
  invoice: null,
};

InvoiceHeader.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceHeader;
