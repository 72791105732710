import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';

const CompanySchema = Yup.object().shape({
  document_type: Yup.mixed()
    .oneOf(['CPF', 'CNPJ', 'UNDEFINED'])
    .nullable(true),
  company_name: Yup.string().required().nullable(true),
  trading_name: Yup.string().required().nullable(true),
  document_number: Yup.string().when('document_type', {
    is: 'CPF',
    then: Yup.string().test('is-cpf', 'CPF inválido', value => validateCPF(value)),
    otherwise: Yup.string().when('document_type', {
      is: 'CNPJ',
      then: Yup.string().test('is-cnpj', 'CNPJ inválido', value => validateCNPJ(value)),
      otherwise: Yup.string().when('document_type', {
        is: 'UNDEFINED',
        then: Yup.string().required().nullable(true),
      }),
    }),
  }),
  address_city: Yup.string().max(255).required().nullable(true),
  address_state: Yup.string().max(2).required().nullable(true),
  address_city_ibge: Yup.number().required().nullable(true),
  address_state_ibge: Yup.number().required().nullable(true),
  market_segment_id: Yup.string().required().nullable(true),
});

export default CompanySchema;
