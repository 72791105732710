import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, ListGroup } from 'react-bootstrap';
import classNames from 'classnames';

import { Button } from '_components/_core';
import BillingInformationModal from '../../BillingInformationModal/BillingInformationModalContainer';

import { CustomCard, CustomCardBody, Label } from '../../../../../styles';

function SubscriptionActionsCard({ subscription, onChangeView }) {
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);

  const { type, companies } = subscription;

  if (!subscription) {
    return null;
  }

  return (
    <>
      <BillingInformationModal
        subscription={subscription}
        isVisible={isBillingInfoModalOpen}
        onModalToggle={() => setIsBillingInfoModalOpen(!isBillingInfoModalOpen)}
      />
      <CustomCard className="mt-3 mt-lg-0">
        <CustomCardBody>
          <Label>Ações</Label>
          <Row className="mt-2">
            <Col>
              <ListGroup
                style={{
                  border: 'none !important',
                  margin: '0',
                }}
              >
                <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                  <Button
                    className="m-0 p-0"
                    onClick={() => onChangeView('CHANGE_PLAN')}
                    variant="link"
                    disabled={!['trialing', 'active'].includes(subscription.status)}
                  >
                    Alterar plano da assinatura
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                  <Button
                    className={classNames({
                      'm-0 p-0': true,
                    })}
                    onClick={() => onChangeView('CHANGE_PAYMENT_METHOD')}
                    variant="link"
                    disabled={[
                      'canceled',
                      'trialing',
                      'trial-ended',
                      'incomplete',
                    ].includes(subscription.status)}
                  >
                    Alterar forma de pagamento
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                  <Button
                    className="m-0 p-0"
                    onClick={() => setIsBillingInfoModalOpen(true)}
                    variant="link"
                  >
                    Alterar dados de cobrança
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                  <Button
                    className="m-0 p-0"
                    onClick={() => onChangeView('INVOICE_HISTORY')}
                    variant="link"
                  >
                    Ver histórico de faturas
                  </Button>
                </ListGroup.Item>
                {type === 'MANAGER' && companies.max > 1 && (
                  <>
                    <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                      <Button
                        className="m-0 p-0"
                        onClick={() => onChangeView('ADDITIONAL_USERS')}
                        variant="link"
                      >
                        Adquirir usuários adicionais
                      </Button>
                    </ListGroup.Item>
                    <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                      <Button
                        className="m-0 p-0"
                        onClick={() => onChangeView('ADDITIONAL_COMPANIES')}
                        variant="link"
                      >
                        Adquirir empresas adicionais
                      </Button>
                    </ListGroup.Item>
                  </>
                )}
                {subscription.status === 'active' && (
                  <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                    <Button
                      className="m-0 p-0  text-danger"
                      variant="link"
                      onClick={() => onChangeView('CANCEL_SUBSCRIPTION')}
                    >
                      Cancelar assinatura
                    </Button>
                  </ListGroup.Item>
                )}
                {['trialing', 'trial-ended', 'canceled'].includes(
                  subscription.status,
                ) && (
                  <ListGroup.Item className="p-0 pt-1 pb-1" style={{ border: 'none' }}>
                    <Button
                      className="m-0 p-0 text-success"
                      variant="link"
                      onClick={() => onChangeView('CHECKOUT')}
                    >
                      {subscription.status === 'canceled'
                        ? 'Reativar assinatura'
                        : 'Assinar agora'}
                    </Button>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
        </CustomCardBody>
      </CustomCard>
    </>
  );
}

SubscriptionActionsCard.defaultProps = {
  subscription: null,
};

SubscriptionActionsCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionActionsCard;
