import { useEffect, useMemo, useRef } from 'react';

import WebSocketManager from './WebSocketManager';

const useWebsocket = namespace => {
  const managerRef = useRef(null);

  useMemo(() => {
    managerRef.current = new WebSocketManager(namespace);
    managerRef.current.connect();
  }, [namespace]);

  useEffect(() => {
    return () => {
      if (managerRef.current) {
        managerRef.current.disconnect();
      }
    };
  }, [namespace]);

  const subscribe = (event, callback) => {
    managerRef.current?.subscribe(event, callback);
  };

  const emit = (event, data) => {
    managerRef.current?.emit(event, data);
  };

  const joinRoom = room => {
    managerRef.current?.joinRoom(room);
  };

  const leaveRoom = room => {
    managerRef.current?.leaveRoom(room);
  };

  return {
    subscribe,
    emit,
    joinRoom,
    leaveRoom,
  };
};

export default useWebsocket;
