import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const migrateUser = callback => {
  return dispatch => {
    confirmDialog.warning({
      title: 'Migrar Usuário',
      message:
        'Ao confirmar sua conta será migrada para o novo sistema de assinaturas e será necessário realizar o login novamente. <br /><br /> Deseja continuar ?',
      confirmButtonText: 'Sim, migrar',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request());

        service
          .migrateUser()
          .then(response => {
            dispatch(success(response));

            dispatch(alertActionCreators.success('Migração realizada com sucesso!'));

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.USER_MIGRATION_REQUEST,
      payload: {},
    };
  }
  function success(response) {
    return {
      type: constants.USER_MIGRATION_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.USER_MIGRATION_FAILURE,
      payload: error,
    };
  }
};

export default migrateUser;
