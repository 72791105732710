import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';

import { StyledOverlayTrigger, MenuEntry, StyledMenuText, StyledPopover } from './styles';

function Dropdown({ options = [], placement, children, ...props }) {
  const popover = useCallback(() => {
    return (
      <StyledPopover>
        <Popover.Content>
          <>
            {options.map((option, index) => (
              <MenuEntry
                key={index}
                onClick={e => {
                  document.body.click();

                  if (option.onClick) {
                    option.onClick(e);
                  }
                }}
                disabled={option.disabled || false}
              >
                {option.icon}
                <StyledMenuText>{option.label}</StyledMenuText>
              </MenuEntry>
            ))}
          </>
        </Popover.Content>
      </StyledPopover>
    );
  }, [options]);

  return (
    <StyledOverlayTrigger
      trigger="click"
      placement={placement}
      overlay={popover()}
      rootClose
      {...props}
    >
      {children}
    </StyledOverlayTrigger>
  );
}

Dropdown.defaultProps = {
  options: [],
  placement: 'bottom',
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
