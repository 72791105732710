import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import { FaChevronDown } from 'react-icons/fa';

export const TransactionsListMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fff;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -15px;
  position: sticky;
  top: calc(49px + var(--actions-bar-height, 0px));
  z-index: 9;
`;

export const HeaderBalance = styled.div`
  padding-top: 8px;
`;

export const Subheader = styled.div`
  background-color: #fff;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 8px;
  position: sticky;
  top: calc(144px + var(--actions-bar-height, 0px));
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e5e5e5; */
  box-shadow: 0 1px 5px -3px rgb(0 0 0 / 75%);
  z-index: 1;

  input {
    margin: 4px 4px;
  }
`;

export const StyledDate = styled.div`
  padding: 8px 16px;
  font-size: 0.85rem;
  font-weight: 500;
  color: #9598AA;
  background-color: #F4F5F8;
  /* position: sticky; */
  /* top: 198px; */
`;

export const Footer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 1px 5px -3px rgb(0 0 0 / 75%);
`;

export const DateDisplay = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const Spinner = styled.div`
  width: 32px;
  height: 32px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const EmptyText = styled.div`
  font-size: 1rem;
  color: #999;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #f7f6f6;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const StyledDescriptionContainer = styled.div`
  width: 80%;
`;

export const Description = styled.div`
  font-size: .75em;
  flex-grow: 1;
  font-weight: 500;
  color: #1A1A1C;
`;

export const Category = styled.div`
  margin-top: 2px;
  font-size: 0.65em;
  color: #919191;
  display: flex;
  align-items: center;

`;

export const Type = styled.div`
  margin-top: 4px;
  font-size: 12.8px;
  color: #919191;
`;

export const Value = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  text-align: right;
  color: #1A1A1C;
`;

export const PaidUnpaid = styled.div`
  color: #999;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ paid }) =>
    paid &&
    css`
    color: #2ecc71;
  `}
`;

export const ActiveFiltersContainer = styled.div`
  position: sticky;
  top: 204px;
  z-index: 1;
`;

export const CashflowOverviewContainer = styled.div`
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid #e5e5e5;
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
`;

export const StyledFormCheck = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 17px !important;
    height: 17px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const StyledChevron = styled(FaChevronDown)`

`;

export const IconContainer = styled.div`
  &&& {
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -33px;
      bottom: -33px;
      left: 0; 
      right: -10px;
      background-color: transparent;
    }
  }
`;
