import React, { useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import { PERMISSIONS } from 'helpers';

import { Button } from '_components/_core';
import { StyledFormCheckbox } from './styles';

export default function TabPermissions({ selectedUserId, allUserPermissions, onSubmit }) {
  const handlePermissionsChange = (permission, setFieldValue, values) => {
    setFieldValue(permission, !values[permission]);
  };

  const handleClearFields = useCallback(setFieldValue => {
    PERMISSIONS.forEach(permission => {
      setFieldValue(permission.key, false);
    });
  }, []);

  const handleGrantAll = useCallback(setFieldValue => {
    PERMISSIONS.forEach(permission => {
      setFieldValue(permission.key, true);
    });
  }, []);

  const renderPermissions = (values, setFieldValue, type = null) => {
    let validPermissions = [];

    if (type) {
      validPermissions = PERMISSIONS.filter(p => p.type === type && !p.hidden);
    } else {
      validPermissions = PERMISSIONS.filter(p => !p.type && !p.hidden);
    }

    return validPermissions.map(permission => (
      <ListGroup.Item key={permission.key} className="p-0 pl-3">
        <StyledFormCheckbox
          checked={values[permission.key]}
          type="checkbox"
          id={permission.key}
          label={permission.label}
          className={classNames({
            'text-muted': !values[permission.key],
          })}
          onChange={() => handlePermissionsChange(permission.key, setFieldValue, values)}
        />
      </ListGroup.Item>
    ));
  };

  return (
    <Formik
      initialValues={{
        user: null,
        allUserPermissions: allUserPermissions[selectedUserId],
        user_id: selectedUserId,
        ...allUserPermissions[selectedUserId],
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <>
          <h4>Tela Transações - Abas</h4>
          <Form.Row className="mt-4" as={Row}>
            <Col xl={2} sm={12}>
              <h5>
                <strong className="text-success">Recebimentos</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue, 'INCOME')}</ListGroup>
            </Col>
            <Col xl={2} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-danger">Despesas Fixas</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'FIXED_EXPENSE')}
              </ListGroup>
            </Col>
            <Col xl={2} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-danger">Despesas Variáveis</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'VARIABLE_EXPENSE')}
              </ListGroup>
            </Col>
            <Col xl={2} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-danger">Pessoas</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue, 'PEOPLE')}</ListGroup>
            </Col>
            <Col xl={2} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-danger">Impostos</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue, 'TAXES')}</ListGroup>
            </Col>
            <Col xl={2} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-info">Transferências</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'TRANSFER')}
              </ListGroup>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xl={3} sm={12} className="mt-3">
              <h5>
                <strong>Outras seções</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'TRANSACTIONS_1')}
              </ListGroup>
            </Col>
            <Col xl={3} sm={12} className="mt-3">
              <h5>
                <strong>&nbsp;</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'TRANSACTIONS_2')}
              </ListGroup>
            </Col>
          </Form.Row>
          <hr className="mt-4 mb-4" />
          <h4>Outras operações no Zenply</h4>
          <Form.Row className="mt-4" as={Row}>
            <Col xl={3} sm={12}>
              <h5>
                <strong className="text-gray">Recursos principais</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'VIEW_DATA')}
              </ListGroup>
            </Col>
            <Col xl={3} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-gray">Outros recursos</strong>
              </h5>
              <ListGroup>
                {renderPermissions(values, setFieldValue, 'CORE_COMPANY')}
              </ListGroup>
            </Col>
            <Col xl={3} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-gray">Cadastros</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue, 'CORE')}</ListGroup>
            </Col>
            <Col xl={3} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-gray">Arquivos</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue, 'FILES')}</ListGroup>
            </Col>
            <Col xl={3} sm={12} className="mt-3 mt-xl-0">
              <h5>
                <strong className="text-gray">Outras</strong>
              </h5>
              <ListGroup>{renderPermissions(values, setFieldValue)}</ListGroup>
            </Col>
          </Form.Row>
          <hr className="mt-4 mb-4" />
          <Row className="float-right">
            <Col>
              <Button
                variant="default"
                className="mr-3"
                onClick={() => handleClearFields(setFieldValue)}
              >
                Remover todas
              </Button>
              <Button
                variant="default"
                className="mr-3"
                onClick={() => handleGrantAll(setFieldValue)}
              >
                Conceder todas
              </Button>
              <Button variant="dark" onClick={handleSubmit}>
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
}

TabPermissions.defaultProps = {
  allUserPermissions: {},
};

TabPermissions.propTypes = {
  selectedUserId: PropTypes.string.isRequired,
  allUserPermissions: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
