import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Col, Row } from 'react-bootstrap';
import { LuTrash, LuArrowLeft, LuRefreshCcw, LuCheck, LuX } from 'react-icons/lu';
import { DateTime } from 'luxon';
import JsonView from '@uiw/react-json-view';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useMediaQuery } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, Tag } from '_components/_core';
import { LoadingIcon } from '_components/_shared';
import { useTable } from '_components/_core/Table/utils';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';

import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../../../styles';

import {
  EventsList,
  EventsContainer,
  Event,
  EventIcon,
  EventDetails,
  EventDetail,
  EventName,
  EventDate,
  EventResponse,
  EventRequest,
  StatusCode,
  EventContainer,
  Counter,
  EventListName,
  EventListDate,
} from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'created_at', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function WebhookEvents({
  isLoading,
  webhook,
  events,
  eventsTotal,
  onFetchWebhookEvents,
  onResendWebhookEvent,
  onDeleteWebhookEvent,
  onBack,
}) {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { isMobile } = useMediaQuery();

  const handleFetchWebhookEvents = useCallback(
    params => {
      if (!webhook) {
        return;
      }

      onFetchWebhookEvents(webhook.id, params, foundData => {
        const { data } = foundData;

        if (data.length > 0 && !isMobile) {
          setSelectedEvent(data[0]);
        }
      });
    },
    [onFetchWebhookEvents, isMobile, webhook],
  );

  const { pagination, onPageChange, onPageSizeChange } = useTable({
    ...tableConfig,
    data: events,
    onFetchData: handleFetchWebhookEvents,
  });

  const eventsGroupedByDay = events.reduce((acc, event) => {
    const date = DateTime.fromISO(event.created_at).toFormat('yyyy-MM-dd');

    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(event);

    return acc;
  }, {});

  const handleResendEvent = useCallback(
    event => {
      onResendWebhookEvent(event.webhook_event_id);
    },
    [onResendWebhookEvent],
  );

  const handleRemoveEvent = useCallback(
    event => {
      onDeleteWebhookEvent(event.webhook_event_id, () => {
        setSelectedEvent(prevEvent => ({ ...prevEvent, event_status: 'IGNORED' }));
      });
    },
    [onDeleteWebhookEvent],
  );

  const renderLoading = useCallback(() => {
    return (
      <div className="mt-5 mb-5">
        <LoadingIcon text="Carregando..." />
      </div>
    );
  }, []);

  const eventNames = useMemo(() => {
    if (isEmpty(webhook) || isEmpty(webhook.events)) {
      return null;
    }

    const sortedEvents = webhook.events.sort();

    const eventNames = sortedEvents.map(event => `<div>${event}</div>`).join('');

    return eventNames;
  }, [webhook]);

  if (!webhook) {
    return null;
  }

  const eventCount = webhook.events ? webhook.events.length : 0;

  const status = webhook.enabled ? (
    <Tag variant="success" className="p-0 m-0 pl-2 pr-2">
      Ativado
    </Tag>
  ) : (
    <Tag variant="danger" className="p-0 m-0 pl-2 pr-2">
      Desativado
    </Tag>
  );

  return (
    <Row>
      <Col>
        <Button
          size="sm"
          variant="link"
          className="m-0 p-0 d-flex justify-content-center align-items-center mb-3"
          onClick={onBack}
        >
          <LuArrowLeft className="mr-2" />
          Voltar para webhooks
        </Button>
        <CustomCard>
          <CustomCardHeader className="d-flex flex-column justify-content-center align-items-start">
            <h3>{webhook.name}</h3>
            <span className="text-muted">{webhook.url}</span>
          </CustomCardHeader>
          <CustomCardBody noPadding>
            <EventContainer>
              <Counter className="mb-0 pr-3">
                <span className="counter-label">Status</span>
                <div>{status}</div>
              </Counter>
              <Counter
                className="mb-0 pl-3"
                data-tip={eventNames}
                style={{ borderLeft: '1px solid #F1F1F4' }}
              >
                <span className="counter-label">Escutando</span>
                <div>
                  <Tag
                    variant="default"
                    className="p-0 m-0 pl-2 pr-2"
                  >{`${eventCount} ${pluralize('evento', eventCount)}`}</Tag>
                </div>
              </Counter>
              <ReactTooltip place="bottom" html />
            </EventContainer>
            <EventsContainer isMobile={isMobile}>
              {isLoading && renderLoading()}
              {!isLoading && (
                <EventsList
                  className={classNames({
                    'd-none': isMobile && selectedEvent,
                  })}
                >
                  {Object.keys(eventsGroupedByDay).map(date => {
                    const events = eventsGroupedByDay[date];

                    return (
                      <div key={date}>
                        <EventDate>
                          {DateTime.fromISO(date)
                            .toFormat("dd 'de' MMM. 'de' yyyy")
                            .toUpperCase()}
                        </EventDate>
                        {events.map(event => {
                          return (
                            <Event
                              key={event.id}
                              onClick={() => setSelectedEvent(event)}
                              selected={selectedEvent && selectedEvent.id === event.id}
                            >
                              <EventIcon>
                                {event.success && (
                                  <Tag
                                    variant="success"
                                    className="p-1 pl-1 pr-1"
                                    style={{ borderRadius: '6px' }}
                                  >
                                    <LuCheck size={14} />
                                  </Tag>
                                )}
                                {!event.success && (
                                  <Tag
                                    variant="danger"
                                    className="p-1 pl-1 pr-1"
                                    style={{ borderRadius: '6px' }}
                                  >
                                    <LuX size={14} />
                                  </Tag>
                                )}
                              </EventIcon>
                              <EventDetails>
                                <EventListName isMobile={isMobile}>
                                  {event.event_name}
                                </EventListName>
                                <EventListDate isMobile={isMobile}>
                                  {FORMATTERS.DATE_HHMMSS(event.created_at)}
                                </EventListDate>
                              </EventDetails>
                            </Event>
                          );
                        })}
                      </div>
                    );
                  })}
                  {Object.keys(eventsGroupedByDay) > 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-1 mt-md-0">
                      <Pagination
                        {...pagination}
                        total={eventsTotal}
                        onPageChange={onPageChange}
                        size="sm"
                        className="mr-2"
                      />
                      <ItemsPerPage
                        itemsPerPage={pagination.itemsPerPage}
                        onChange={onPageSizeChange}
                        total={eventsTotal}
                        totalBeingShown={0}
                        className="ml-2"
                      />
                    </div>
                  )}
                </EventsList>
              )}
              {selectedEvent && (
                <EventDetail>
                  {isMobile && (
                    <Button
                      variant="link"
                      className="m-0 p-0"
                      onClick={() => setSelectedEvent(null)}
                    >
                      <LuArrowLeft className="mr-2" />
                      Voltar para lista
                    </Button>
                  )}
                  <EventName
                    className={classNames({
                      'd-flex': true,
                      'justify-content-between align-items-center': true,
                    })}
                  >
                    {selectedEvent.event_name}
                    <div
                      className={classNames({
                        'd-flex justify-content-center align-items-center': true,
                        'mt-2': isMobile,
                      })}
                    >
                      <Button
                        size="sm"
                        variant="default"
                        className="d-flex justify-content-center align-items-center mr-2"
                        onClick={() => handleResendEvent(selectedEvent)}
                        disabled={selectedEvent.event_status === 'IGNORED'}
                      >
                        <LuRefreshCcw className="mr-0 mr-md-2" />
                        {!isMobile ? 'Reenviar' : null}
                      </Button>
                      <Button
                        size="sm"
                        variant="inverse-danger"
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => handleRemoveEvent(selectedEvent)}
                        disabled={selectedEvent.event_status === 'IGNORED'}
                      >
                        <LuTrash className="mr-0 mr-md-2" />
                        {!isMobile ? 'Remover' : null}
                      </Button>
                    </div>
                  </EventName>
                  {selectedEvent.event_status === 'IGNORED' && (
                    <div className="d-flex justify-content-end align-items-center text-center">
                      <Tag variant="full" className="mt-2">
                        Este evento foi removido e não pode ser reenviado.
                      </Tag>
                    </div>
                  )}
                  <EventResponse>
                    <h6>Resposta</h6>
                    <div>
                      <StatusCode className="mt-3 mb-3">
                        <span>
                          <span className="text-muted">Código de status HTTP:</span>
                          <span className="ml-1">
                            {`${selectedEvent.http_status} ${selectedEvent.http_status_text ? `(${selectedEvent.http_status_text})` : ''}`}
                          </span>
                        </span>
                      </StatusCode>
                      {selectedEvent.response && (
                        <>
                          {selectedEvent.response_content_type.includes('json') && (
                            <div className="mb-4">
                              <JsonView
                                value={JSON.parse(selectedEvent.response)}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                shortenTextAfterLength={0}
                              />
                            </div>
                          )}
                          {!selectedEvent.response_content_type.includes('json') && (
                            <div className="mb-4">{selectedEvent.response}</div>
                          )}
                        </>
                      )}
                    </div>
                  </EventResponse>
                  <EventRequest>
                    <h6>Conteúdo enviado</h6>
                    <div className="mt-4 mb-4">
                      <JsonView
                        value={selectedEvent.request}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        shortenTextAfterLength={0}
                      />
                    </div>
                  </EventRequest>
                </EventDetail>
              )}
            </EventsContainer>
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

WebhookEvents.propTypes = {
  isLoading: PropTypes.bool,
  webhook: PropTypes.object,
  events: PropTypes.array,
  onFetchWebhookEvents: PropTypes.func,
  onResendWebhookEvent: PropTypes.func,
  onBack: PropTypes.func,
  onDeleteWebhookEvent: PropTypes.func,
  eventsTotal: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default WebhookEvents;
