import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { LuComponent, LuCreditCard } from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';

import InvoicePaymentBoleto from './InvoicePaymentBoleto';
import InvoicePaymentCard from './InvoicePaymentCard';

import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../../styles';
import { Box, BoxLabel, BoxText, BoxValue, Text } from '../styles';

const PAYMENT_METHOD_ICON = {
  CREDIT_CARD: <LuCreditCard className="mr-2" />,
  PIX: <LuComponent className="mr-2" />,
  BOLETO: <FaBarcode className="mr-2" />,
};

function InvoicePayment({
  invoice,
  isRefreshing,
  onRefreshPayment,
  onFetchInvoice,
  onSetInvoice,
}) {
  const { reason, metadata, total_amount } = invoice;

  const isInInvalidStatus = ['void', 'uncollectible'].includes(invoice.status);
  const isChangePlanInvoice =
    metadata?.action === 'CHANGE_PLAN' &&
    reason === 'SUBSCRIPTION_UPDATE' &&
    total_amount === 0;

  return (
    <Row className="mt-3">
      <Col>
        <CustomCard>
          <CustomCardHeader>
            <h3>Pagamento da fatura</h3>
          </CustomCardHeader>
          <CustomCardBody>
            {isChangePlanInvoice && (
              <Row>
                <Col>
                  <Text className="text-muted">
                    Fatura com valor zerado, não há cobrança associada.
                  </Text>
                </Col>
              </Row>
            )}
            {invoice.status === 'paid' && !isChangePlanInvoice && (
              <>
                <Row>
                  <Col xs={6} md={3}>
                    <Box>
                      <BoxLabel>Situação</BoxLabel>
                      <BoxValue status={invoice.status}>Pago</BoxValue>
                    </Box>
                  </Col>
                  <Col xs={6} md={4}>
                    <Box>
                      <BoxLabel>Forma de pagamento</BoxLabel>
                      <BoxValue status={invoice.status}>
                        <span className="d-flex justify-content-start align-items-center text-capitalize">
                          {PAYMENT_METHOD_ICON[invoice.payment_method]}
                          {invoice.payment_method_description}
                        </span>
                      </BoxValue>
                    </Box>
                  </Col>
                  <Col xs={6} md={3}>
                    &nbsp;
                  </Col>
                </Row>
              </>
            )}
            {!isInInvalidStatus && invoice.status !== 'paid' && (
              <>
                {invoice.payment_method === 'BOLETO' && (
                  <InvoicePaymentBoleto
                    invoice={invoice}
                    isRefreshing={isRefreshing}
                    onRefreshPayment={onRefreshPayment}
                  />
                )}
                {invoice.payment_method === 'CREDIT_CARD' && (
                  <InvoicePaymentCard
                    invoice={invoice}
                    isRefreshing={isRefreshing}
                    onRefreshPayment={onRefreshPayment}
                    onFetchInvoice={onFetchInvoice}
                    onSetInvoice={onSetInvoice}
                  />
                )}
              </>
            )}
            {isInInvalidStatus && (
              <Row>
                <Col>
                  <div>
                    <BoxText>
                      Fatura inválida, não é possível realizar o pagamento. Se necessário,
                      faça uma nova assinatura.
                    </BoxText>
                  </div>
                </Col>
              </Row>
            )}
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

InvoicePayment.defaultProps = {
  invoice: null,
};

InvoicePayment.propTypes = {
  invoice: PropTypes.object,
};

export default InvoicePayment;
