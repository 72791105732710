import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Col, Dropdown } from 'react-bootstrap';
import { TiArrowSortedDown } from 'react-icons/ti';
import { FaSearch } from 'react-icons/fa';
import { LuBuilding, LuCreditCard, LuExternalLink } from 'react-icons/lu';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useMediaQuery } from 'helpers';
import {
  Button,
  FormTextField,
  FormCpfCnpjField,
  FloatingCard,
  Tabs,
  HintIcon,
} from '_components/_core';
import {
  AddressFormFields,
  FormMarketSegmentField,
  LoadingIcon,
  PermissionsGate,
} from '_components/_shared';

import { CompanySchema } from './utilities';
import {
  ModalFooter,
  StyledButton,
  SubscriptionSelectContainer,
  RequeridedIndicator,
} from './styles';

const PLACEHOLDERS = {
  document_type_CPF: 'CPF',
  document_type_CNPJ: 'CNPJ',
  document_type_UNDEFINED: 'Sem documento',
  first_name_CPF: 'Nome',
  first_name_CNPJ: 'Razão Social',
  last_name_CPF: 'Apelido',
  last_name_CNPJ: 'Nome Fantasia',
  first_name_UNDEFINED: 'Nome',
  last_name_UNDEFINED: 'Apelido',
};

const CompanyFormModal = ({
  isVisible,
  // isLoading: oldIsLoading,
  isSearching,
  company_id,
  selectedCompany: oldCompany,
  onAddFinancialCompany,
  onUpdateFinancialCompany,
  onFetchCompany,
  onFetchMarketSegments,
  onToggleForm,
  onClearSearchedCompany,
  onSearchCompanyByCnpj,
  onFetchCities,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
  onFetchManagerSubscription,
}) => {
  const history = useHistory();

  const { isMobile } = useMediaQuery();

  const [newCompany, setNewCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [managerSubscription, setManagerSubscription] = useState(null);

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (!company_id) {
      setNewCompany({});
      return;
    }

    setIsLoading(true);

    onFetchCompany(company_id, found_company => {
      setNewCompany(found_company);

      setIsLoading(false);
    });
  }, [company_id, onFetchCompany, isVisible]);

  useEffect(() => {
    onFetchManagerSubscription(managerSubscription => {
      setManagerSubscription(managerSubscription);
    });
  }, [onFetchManagerSubscription]);

  const handleCompanySearch = useCallback(
    (values, setFieldValue, validateForm) => {
      onClearSearchedCompany();

      onSearchCompanyByCnpj(values.document_number, searchedCompany => {
        onFetchCities(searchedCompany.address_state_ibge, () => {
          setFieldValue('company_name', searchedCompany.company_name);
          setFieldValue('trading_name', searchedCompany.trading_name);
          setFieldValue('address_zip_code', searchedCompany.address_zip_code);
          setFieldValue('address_street', searchedCompany.address_street);
          setFieldValue('address_number', searchedCompany.address_number);
          setFieldValue('address_district', searchedCompany.address_district);
          setFieldValue('address_complement', searchedCompany.complement);
          setFieldValue('address_city', searchedCompany.address_city);
          setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
          setFieldValue('address_state', searchedCompany.address_state);
          setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));

          validateForm();
        });
      });
    },
    [onSearchCompanyByCnpj, onFetchCities, onClearSearchedCompany],
  );

  const initialValues = useMemo(() => {
    if (!company_id && isEmpty(oldCompany)) {
      return {
        company_name: '',
        trading_name: '',
        document_number: '',
        address_state_ibge: '',
        address_city_ibge: '',
        address_state: '',
        address_city: '',
        address_street: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_zip_code: '',
        market_segment_id: null,
        status_irs: 'ATIVA',
        date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
        document_type: 'CNPJ',
        balance_type: 'POSITIVO',
        subscription: 'existing',
      };
    }

    return {
      company_name: newCompany ? newCompany.company_name : '',
      trading_name: newCompany ? newCompany.trading_name : '',
      document_number: newCompany ? newCompany.document_number : '',
      address_state_ibge: newCompany ? newCompany.address_state_ibge : '',
      address_city_ibge: newCompany ? newCompany.address_city_ibge : '',
      address_state: newCompany ? newCompany.address_state : '',
      address_city: newCompany ? newCompany.address_city : '',
      address_street: newCompany ? newCompany.address_street : '',
      address_number: newCompany ? newCompany.address_number : '',
      address_complement: newCompany ? newCompany.address_complement : '',
      address_district: newCompany ? newCompany.address_district : '',
      address_zip_code: newCompany ? newCompany.address_zip_code : '',
      market_segment_id: newCompany ? newCompany.market_segment_id : '',
      status_irs: 'ATIVA',
      date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
      document_type: newCompany ? newCompany.document_type : '',
      balance_type: 'POSITIVO',
    };
  }, [company_id, newCompany, oldCompany]);

  const selectedCompany = useMemo(() => {
    if (company_id) {
      return newCompany;
    }

    return oldCompany;
  }, [company_id, newCompany, oldCompany]);

  const handleSubmitForm = useCallback(
    (values, { resetForm }) => {
      setIsLoading(true);

      if (onBeforeSaveCallback) {
        onBeforeSaveCallback(values);
      }

      if (selectedCompany && selectedCompany.id) {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        onUpdateFinancialCompany(selectedCompany.id, formattedValues, updatedCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(updatedCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(updatedCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }

          setIsLoading(false);
        });
      } else {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        const callback = createdCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(createdCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(createdCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }
          setIsLoading(false);
        };

        const errorCallback = () => {
          setIsLoading(false);
        };

        onAddFinancialCompany(formattedValues, false, callback, errorCallback);
      }
    },
    [
      onToggleForm,
      onUpdateFinancialCompany,
      onAddFinancialCompany,
      selectedCompany,
      onAfterSaveCallback,
      onAfterSaveCallbackWithReset,
      onBeforeSaveCallback,
    ],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button
          type="submit"
          variant="success-2"
          className="mr-2 flex-fill"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          Salvar alterações
        </Button>
        <Button className="flex-fill" variant="inverse-dark" onClick={onToggleForm}>
          Cancelar
        </Button>
      </ModalFooter>
    ),
    [onToggleForm, isLoading],
  );

  const handleGoToSubscription = useCallback(() => {
    history.push('/configuracoes/assinatura');
  }, [history]);

  const getTabs = useCallback(
    ({ values, setFieldValue, validateForm }) => {
      const items = [
        {
          id: 'COMPANY_INFO',
          title: 'Dados da empresa',
          icon: <LuBuilding className="mr-2" />,
          content: (
            <>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Dropdown>
                    <StyledButton
                      variant="link"
                      as={Dropdown.Toggle}
                      className="pl-0 pb-0"
                    >
                      {values.document_type === 'CPF'
                        ? 'CPF'
                        : values.document_type === 'CNPJ'
                          ? 'CNPJ'
                          : 'Sem documento'}
                      <TiArrowSortedDown size="1.1em" className="ml-1" />
                    </StyledButton>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setFieldValue('document_type', 'CPF')}
                      >
                        <option>CPF</option>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFieldValue('document_type', 'CNPJ')}
                      >
                        <option>CNPJ</option>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFieldValue('document_type', 'UNDEFINED');
                          setFieldValue('document_number', '00000000000');
                        }}
                      >
                        <option>Sem documento</option>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="d-flex justify-content-between align-items-start">
                    <div
                      style={{
                        flex: 1,
                      }}
                      className="d-flex flex-column align-items-start justify-content-center"
                    >
                      <FormCpfCnpjField
                        name="document_number"
                        type={values.document_type === 'CNPJ' ? 'CNPJ' : 'CPF'}
                        placeholder="Digite o número do documento"
                        disabled={values.document_type === 'UNDEFINED'}
                      />
                    </div>
                    {values.document_type === 'CNPJ' && (
                      <Button
                        variant="default"
                        className="ml-2"
                        isLoading={isSearching}
                        disabled={isSearching}
                        onClick={() =>
                          handleCompanySearch(values, setFieldValue, validateForm)
                        }
                      >
                        <FaSearch />
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>
                    {PLACEHOLDERS[`first_name_${values.document_type}`]}
                    <RequeridedIndicator>*</RequeridedIndicator>
                  </Form.Label>
                  <FormTextField
                    name="company_name"
                    placeholder={PLACEHOLDERS[`first_name_${values.document_type}`]}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>
                    {PLACEHOLDERS[`last_name_${values.document_type}`]}
                    <RequeridedIndicator>*</RequeridedIndicator>
                  </Form.Label>
                  <FormTextField
                    name="trading_name"
                    placeholder={PLACEHOLDERS[`last_name_${values.document_type}`]}
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                columnSizes={{
                  cep: 6,
                  searchButton: 4,
                  street: isMobile ? 6 : 4,
                  number: isMobile ? 6 : 4,
                  district: isMobile ? 6 : 4,
                  complement: isMobile ? 6 : 4,
                  city: isMobile ? 12 : 4,
                  state: isMobile ? 12 : 4,
                }}
                requiredFields={[
                  'address_city_ibge',
                  'address_state',
                  'address_state_ibge',
                  'address_city',
                ]}
              />
              <hr />
              <Form.Row>
                <Col>
                  <FormMarketSegmentField />
                </Col>
              </Form.Row>
            </>
          ),
        },
        {
          id: 'SUBSCRIPTION',
          title: 'Assinatura',
          icon: <LuCreditCard className="mr-2" />,
          content: (
            <>
              <hr />
              <div className="d-flex flex-column">
                <SubscriptionSelectContainer
                  onClick={() => setFieldValue('subscription', 'existing')}
                >
                  <div>
                    <Form.Check
                      type="radio"
                      name="subscription"
                      id="existing"
                      checked={values.subscription === 'existing'}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="ml-3 d-flex flex-column">
                    <h4>Assinatura existente</h4>
                    <span>
                      Incluir a empresa na assinatura atual.
                      {managerSubscription && managerSubscription.limitMessage && (
                        <span
                          className={
                            managerSubscription.available_seats > 0
                              ? 'text-info'
                              : 'text-danger'
                          }
                        >
                          &nbsp;({managerSubscription.limitMessage})
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <h4>
                      <HintIcon hint="Ao incluir a nova empresa em sua assinatura atual, serão utilizados os mesmos dados de cobrança, plano e ciclo de faturamento." />
                    </h4>
                  </div>
                </SubscriptionSelectContainer>
                <SubscriptionSelectContainer
                  onClick={() => setFieldValue('subscription', 'new')}
                  className="mt-3"
                >
                  <div>
                    <Form.Check
                      type="radio"
                      name="subscription"
                      id="new"
                      checked={values.subscription === 'new'}
                    />
                  </div>
                  <div className="ml-3 d-flex flex-column">
                    <h4>Nova assinatura</h4>
                    <span>
                      Criar uma nova assinatura para a empresa e testar gratuitamente por
                      15 dias.
                    </span>
                  </div>
                  <div className="ml-auto">
                    <h4>
                      <HintIcon hint="Uma nova assinatura será criada para a empresa, você poderá definir dados de cobrança, plano e ciclo de faturamento diferentes de sua assinatura atual." />
                    </h4>
                  </div>
                </SubscriptionSelectContainer>
              </div>
              <Button
                variant="link"
                className="m-0 p-0 d-flex justify-content-center align-items-center mt-3 float-right"
                size="sm"
                style={{ lineHeight: '19.2px' }}
                onClick={handleGoToSubscription}
              >
                Adquirir empresas adicionais
                <LuExternalLink size="1em" className="ml-1" />
              </Button>
            </>
          ),
        },
      ];

      if (company_id) {
        return items.filter(item => item.id !== 'SUBSCRIPTION');
      } else {
        if (
          !managerSubscription ||
          (managerSubscription && !managerSubscription.allowAddCompany)
        ) {
          return items.filter(item => item.id !== 'SUBSCRIPTION');
        }
      }

      return items;
    },
    [
      isSearching,
      isMobile,
      handleCompanySearch,
      handleGoToSubscription,
      managerSubscription,
      company_id,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values, setFieldValue, validateForm }) => (
        <>
          <FloatingCard
            title={company_id ? 'Editar empresa' : 'Cadastrar nova empresa'}
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onToggleForm}
            footerContent={renderModalFooter(handleSubmit, isValid)}
            withCloseButton
            width={900}
            bodyClassName="p-3"
          >
            <PermissionsGate permissions={['manager_company']} fallback>
              {isLoading && (
                <div className="h-100 d-flex justify-content-center">
                  <LoadingIcon text="Aguarde ..." />
                </div>
              )}
              {!isLoading && (
                <>
                  <Form onSubmit={handleSubmit}>
                    <Tabs
                      tabs={getTabs({
                        values,
                        setFieldValue,
                        validateForm,
                      })}
                      variant="pills"
                    />
                  </Form>
                </>
              )}
            </PermissionsGate>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
};

export default CompanyFormModal;

CompanyFormModal.defaultProps = {
  company_id: null,
  selectedCompany: {},
  isSearching: false,
};

CompanyFormModal.propTypes = {
  isVisible: PropTypes.bool,
  onToggleForm: PropTypes.func.isRequired,
  onAddFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  // isLoading: PropTypes.bool,
  company_id: PropTypes.string,
  onFetchCompany: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onAfterSaveCallbackWithReset: PropTypes.func,
};
