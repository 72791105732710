import { connect } from 'react-redux';

import apiKeyActionCreators from '_store/_actions/apiKeys';

import ApiKeys from './ApiKeys';

const mapStateToProps = state => ({
  apiKeys: state.apiKeys.apiKeys,
  isLoading: state.apiKeys.isLoading,
});

const mapDispatchToProps = {
  onFetchKeys: apiKeyActionCreators.getAllKeys,
  onDeleteKey: apiKeyActionCreators.deleteKey,
  onUpdateKey: apiKeyActionCreators.updateKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
