import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { auth } from './auth.reducer';
import { alert } from './alert.reducer';
import { sidebar } from './sidebar.reducer';
import { company } from './company.reducer';
import { user } from './user.reducer';
import { accounts } from './accounts.reducer';
import { balanceSheet } from './balance-sheet.reducer';
import { ledger } from './ledger.reducer';
import { entries } from './entries.reducer';
import { associates } from './associates.reducer';
import { invoices } from './invoices.reducer';
import { ibge } from './ibge.reducer';
import { employees } from './employees.reducer';
import { reports } from './reports.reducer';
import { subscription } from './subscription.reducer';
import { payroll } from './payroll.reducer';
import { taxes } from './taxes.reducer';
import { accountants } from './accountants.reducer';
import { bankStatements } from './bank-statements.reducer';
import { fiscal } from './fiscal.reducer';
import { DRE } from './dre.reducer';
import { automations } from './automations.reducer';
import { companyTransactions } from './company-transactions.reducer';
import { mei } from './mei.reducer';
import { logs } from './logs.reducer';
import { userPermissions } from './userPermissions.reducer';
import { receipts } from './receipts.reducer';
import { payments } from './payments.reducer';
import { apiKeys } from './apiKeys.reducer';
import { integrations } from './integrations.reducer';
import { webhooks } from './webhooks.reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    alert,
    auth,
    sidebar,
    company,
    user,
    balanceSheet,
    accounts,
    ledger,
    entries,
    associates,
    invoices,
    ibge,
    employees,
    reports,
    subscription,
    payroll,
    taxes,
    accountants,
    bankStatements,
    fiscal,
    DRE,
    automations,
    companyTransactions,
    mei,
    logs,
    userPermissions,
    receipts,
    payments,
    apiKeys,
    integrations,
    webhooks,
  });

export default createRootReducer;
