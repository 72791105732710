/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LuBuilding,
  LuComponent,
  LuCreditCard,
  LuMail,
  LuMap,
  LuUser,
} from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

import BillingInformationModal from '../../BillingInformationModal/BillingInformationModalContainer';

import { CustomCard, CustomCardBody, Value } from '../../../../../styles';
import classNames from 'classnames';

const ICONS = {
  CREDIT_CARD: <LuCreditCard size="1.3em" className="mr-2" />,
  BOLETO: <FaBarcode size="1.3em" className="mr-2" />,
  PIX: <LuComponent size="1.3em" className="mr-2" />,
  EMAIL: <LuMail size="1.3em" className="mr-2" />,
  ADDRESS: <LuMap size="1.3em" className="mr-2" />,
  CPF: <LuUser size="1.3em" className="mr-2" />,
  CNPJ: <LuBuilding size="1.3em" className="mr-2" />,
};

function SubscriptionInformationCard({ subscription, onChangeView }) {
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);

  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
  }) => (
    <>
      <Value className="w-100 d-flex justify-content-between align-items-center">
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  return (
    <>
      <CustomCard>
        <BillingInformationModal
          subscription={subscription}
          isVisible={isBillingInfoModalOpen}
          onModalToggle={() => setIsBillingInfoModalOpen(!isBillingInfoModalOpen)}
        />
        <CustomCardBody>
          <InfoItem
            title="Dados de cobrança"
            contentStyle={{ flex: 1, width: '100%' }}
            content={
              <div className="w-100">
                <span className="d-flex align-items-center justify-content-start">
                  <span style={{ flex: 1 }} className="mt-2 mb-1">
                    <span>{subscription.billing_info.name}</span>
                    <br />
                    <span>
                      {FORMATTERS.CPF_CNPJ(subscription.billing_info.document_number)}
                    </span>
                    <br />
                    {subscription.billing_info.email_primary}
                    <div
                      className="w-100 pt-2"
                      style={{
                        flex: 1,
                      }}
                    >
                      <span>
                        {subscription.billing_info.address_street},{' '}
                        {subscription.billing_info.address_number}
                        {subscription.billing_info.address_complement
                          ? ` - ${subscription.billing_info.address_complement}`
                          : ``}
                      </span>
                      <br />
                      <span>
                        {subscription.billing_info.address_city} -{' '}
                        {subscription.billing_info.address_state} <br />
                        {FORMATTERS.CEP(subscription.billing_info.address_zip_code)}
                      </span>
                    </div>
                  </span>
                </span>
              </div>
            }
            onClick={() => setIsBillingInfoModalOpen(true)}
          />
          {['active', 'past_due'].includes(subscription.status) && (
            <>
              <hr />
              <InfoItem
                title="Forma de pagamento"
                content={
                  <>
                    <span className="d-flex align-items-center  mt-2">
                      {ICONS[subscription.payment_method]}{' '}
                      <span className="text-capitalize">
                        {subscription.payment_method_description}
                      </span>
                    </span>
                  </>
                }
                onClick={() => onChangeView('CHANGE_PAYMENT_METHOD')}
              />
            </>
          )}
        </CustomCardBody>
      </CustomCard>
    </>
  );
}

SubscriptionInformationCard.defaultProps = {
  subscription: null,
};

SubscriptionInformationCard.propTypes = {
  subscription: PropTypes.object,
  onGetUpdatePaymentSessionUrl: PropTypes.func.isRequired,
};

export default SubscriptionInformationCard;
