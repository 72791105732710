import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { LuCheckCircle2, LuXCircle } from 'react-icons/lu';
import has from 'lodash/has';

import FORMATTERS from 'helpers/formatters';

import {
  PlanContainer,
  PlanDescription,
  PlanDiscount,
  PlanFeatures,
  PlanName,
  PlanNameContainer,
  PlanPrice,
  StyledButton,
  CustomCardBody,
} from './styles';

function Plan({ isActive, plan, allPlans, onSelectPlan, onChoosePlan }) {
  const {
    cashflow_enabled,
    billing_enabled,
    nfse_enabled,
    max_users,
    max_storage_size_megabytes,
    max_transactions_per_month,
    max_custom_receipts,
    cycle_months,
  } = plan;

  const features = [];

  if (has(plan, 'cashflow_enabled')) {
    features.push({
      description: <span>Fluxo de Caixa</span>,
      enabled: cashflow_enabled,
    });
  }

  if (has(plan, 'billing_enabled')) {
    features.push({
      description: <span>Emissão de Boletos</span>,
      enabled: billing_enabled,
    });
  }

  if (has(plan, 'nfse_enabled')) {
    features.push({
      description: 'Emissão de NFS-e',
      enabled: nfse_enabled,
    });
  }

  if (has(plan, 'max_transactions_per_month')) {
    features.push({
      description: <span>Até {max_transactions_per_month} transações (por mês)</span>,
      enabled: true,
    });
  }

  features.push({
    description: `Até ${max_users} ${pluralize('Usuário', max_users)}`,
    enabled: true,
  });

  features.push({
    description: `Até ${max_custom_receipts} Recibos Personalizados`,
    enabled: true,
  });

  features.push({
    description: `Até ${max_storage_size_megabytes / 1000} GB de armazenamento`,
    enabled: true,
  });

  const hasDiscount = cycle_months === 6 || cycle_months === 12;

  const discountAmount = useMemo(() => {
    const relatedMonthlyPlan = allPlans.find(
      p => p.cycle_months === 1 && p.name === plan.name,
    );

    if (!relatedMonthlyPlan) {
      return 0;
    }

    return relatedMonthlyPlan.price * cycle_months - plan.price;
  }, [cycle_months, allPlans, plan]);

  if (!plan) {
    return null;
  }

  const FeatureItem = ({ feature, enabled }) => (
    <li>
      <div className="d-flex align-items-center">
        {enabled && <LuCheckCircle2 size="1.1rem" className="text-success mr-3" />}
        {!enabled && <LuXCircle size="1.1rem" className="text-danger mr-3" />}
        <span>{feature}</span>
      </div>
    </li>
  );

  return (
    <PlanContainer key={plan.id} active={isActive} onClick={() => onSelectPlan(plan.id)}>
      <CustomCardBody>
        <PlanNameContainer>
          <div>
            <PlanName>{plan.name}</PlanName>
            <PlanDescription>{plan.description}</PlanDescription>
          </div>
          {hasDiscount && discountAmount > 0 && (
            <PlanDiscount>
              <small>Economize {FORMATTERS.NUMBER(discountAmount)}</small>
            </PlanDiscount>
          )}
        </PlanNameContainer>
        <div className="d-flex align-items-center pt-2">
          <PlanPrice>{FORMATTERS.NUMBER(plan.price)}</PlanPrice>
          <PlanDescription className="ml-1">
            {plan.cycle_months === 1 && 'por mês'}
            {plan.cycle_months === 6 && 'a cada 6 meses'}
            {plan.cycle_months === 12 && 'a cada 12 meses'}
          </PlanDescription>
        </div>
        <div>
          <small>por CNPJ</small>
        </div>
        <StyledButton
          variant="inverse-dark"
          className="mt-3"
          isActive={isActive}
          onClick={() => onChoosePlan(plan.id)}
        >
          Selecionar plano
        </StyledButton>
        <hr />
        <PlanFeatures className="mt-3">
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              feature={feature.description}
              enabled={feature.enabled}
            />
          ))}
        </PlanFeatures>
      </CustomCardBody>
    </PlanContainer>
  );
}

Plan.propTypes = {
  isActive: PropTypes.bool,
  plan: PropTypes.object.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  allPlans: PropTypes.array.isRequired,
};

export default Plan;
