import { connect } from 'react-redux';

import webhooksActionsCreators from '_store/_actions/webhooks';

import Webhooks from './Webhooks';

const mapStateToProps = state => ({
  webhooks: state.webhooks.webhooks,
  isLoading: state.webhooks.isLoading,
});

const mapDispatchToProps = {
  onFetchWebhooks: webhooksActionsCreators.getAllWebhooks,
  onDeleteWebhook: webhooksActionsCreators.deleteWebhook,
  onCreateWebhook: webhooksActionsCreators.createWebhook,
  onUpdateWebhook: webhooksActionsCreators.updateWebhook,
};

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
