import { Button, Dropdown, SimpleAlert } from '_components/_core';
import React, { useCallback, useMemo, useState } from 'react';
import { FaBarcode } from 'react-icons/fa';
import { LuArrowUpRight, LuCreditCard, LuPlus } from 'react-icons/lu';

import { isBillingInfoValid } from 'helpers';

import { BillingInformationModal } from '..';

function AddPaymentMethodButton({
  subscription,
  paymentMethods,
  onGetUpdatePaymentSessionUrl,
  onCreatePaymentMethod,
  withBillingModal,
}) {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [showBillingInfoAlert, setShowBillingInfoAlert] = useState(false);

  const hasBoleto = useMemo(() => {
    return paymentMethods.some(paymentMethod => paymentMethod.type === 'BOLETO');
  }, [paymentMethods]);

  const handleAddCard = useCallback(() => {
    setIsRedirecting(true);

    onGetUpdatePaymentSessionUrl(session_url => {
      const anchor = document.createElement('a');
      anchor.href = session_url;
      anchor.style.display = 'none';

      document.body.appendChild(anchor);
      anchor.click();

      setIsRedirecting(false);

      document.body.removeChild(anchor);
    });
  }, [onGetUpdatePaymentSessionUrl]);

  const handleAddBoleto = useCallback(() => {
    const { billing_info } = subscription;

    if (!isBillingInfoValid(billing_info)) {
      setShowBillingInfoAlert(true);

      return;
    }

    onCreatePaymentMethod({
      type: 'BOLETO',
    });
  }, [subscription, onCreatePaymentMethod]);

  return (
    <>
      {withBillingModal && (
        <BillingInformationModal
          isVisible={isAddressModalOpen}
          onModalToggle={() => setIsAddressModalOpen(!isAddressModalOpen)}
          onAfterSaveCallback={() => {
            setShowBillingInfoAlert(false);
          }}
        />
      )}
      <Dropdown
        placement="bottom-start"
        options={[
          {
            label: (
              <div className="d-flex justify-content-start align-items-center">
                Adicionar cartão
                <LuArrowUpRight className="ml-2" />
              </div>
            ),
            icon: <LuCreditCard />,
            onClick: handleAddCard,
          },
          {
            label: 'Adicionar boleto',
            icon: <FaBarcode />,
            onClick: handleAddBoleto,
            disabled: hasBoleto,
          },
        ]}
      >
        <Button
          variant="link"
          className="m-0 p-0 d-flex justify-content-start align-items-center mt-1 mb-3"
          disabled={isRedirecting}
        >
          <LuPlus className="mr-1" />
          {isRedirecting ? 'Redirecionando...' : 'Adicionar nova forma de pagamento'}
        </Button>
      </Dropdown>
      {showBillingInfoAlert && (
        <SimpleAlert variant="warning">
          <Button
            variant="link"
            className="p-0 m-0"
            onClick={() => setIsAddressModalOpen(true)}
          >
            Antes de adicionar a opção boleto, complete os dados de cobrança.
          </Button>
        </SimpleAlert>
      )}
    </>
  );
}

export default AddPaymentMethodButton;
