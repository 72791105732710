import createWebhook from './createWebhook';
import deleteWebhook from './deleteWebhook';
import getAllWebhooks from './getAllWebhooks';
import updateWebhook from './updateWebhook';
import fetchWebhookEvents from './fetchWebhookEvents';
import resendWebhookEvent from './resendWebhookEvent';
import deleteWebhookEvent from './deleteWebhookEvent';

export default {
  createWebhook,
  deleteWebhook,
  getAllWebhooks,
  updateWebhook,
  fetchWebhookEvents,
  resendWebhookEvent,
  deleteWebhookEvent,
};
