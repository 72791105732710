import styled from 'styled-components';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { LuUser, LuBell, LuBuilding2 } from 'react-icons/lu';
import { IoLogOutOutline } from 'react-icons/io5';

export const StyledOverlayTrigger = styled(OverlayTrigger)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(1rem + 12.5px) !important;
`;

export const StyledWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  width: auto;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledDiv = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
   
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%; 
`;

export const StyledAvatar = styled.img`
  width: 32px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const StyledPopover = styled(Popover)`
  min-width: 210px;
  padding: 0 !important;
  color: rgb(42, 46, 52);
  font-family: var(--font-family);
  border: none;

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }
`;

export const MenuEntry = styled.div`
  margin: 0.5rem 0;
  border-radius: 4px;
  
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledUserName = styled.h1`
  font-size: 13px;
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 0.1rem;
`;

export const StyledUserRole = styled.h2`
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;
  margin-bottom: 0;
`;

export const StyledMenuText = styled.h1`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
`;

export const StyledLuBuilding2 = styled(LuBuilding2)`
    color: #333333;
`;

export const StyledLuBell = styled(LuBell)`
    color: #333333;
`;

export const StyledLuUser = styled(LuUser)`
    color: #333333;
`;

export const StyledIoLogOutOutline = styled(IoLogOutOutline)`
    color: #333333;
`;

export const Footer = styled.div`
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #a8abad !important;
  }

  svg {
    color: #a8abad !important;
    padding: 0;
    margin: 0;
  }
`;
