import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';
import alertActionCreators from '../alert.actions';

const createPaymentMethod = (params, callback) => {
  return dispatch => {
    dispatch(request());

    service
      .createPaymentMethod(params)
      .then(response => {
        const { data } = response;

        dispatch(success(data));

        dispatch(
          alertActionCreators.success('Forma de pagamento cadastrada com sucesso!'),
        );

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.CREATE_PAYMENT_METHOD_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_PAYMENT_METHOD_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_PAYMENT_METHOD_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default createPaymentMethod;
