import constants from '../../_constants/integrations.constants';
import service from '../../../services/integrations.service';
import alertActionCreators from '../alert.actions';

const updateIntegration = (id, data, callback) => {
  return dispatch => {
    dispatch(request(data));

    service
      .updateIntegration(id, data)
      .then(response => {
        dispatch(success(response));

        dispatch(alertActionCreators.success('Integração atualizada com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(data) {
    return {
      type: constants.UPDATE_INTEGRATION_REQUEST,
      payload: { data },
    };
  }
  function success(response) {
    return {
      type: constants.UPDATE_INTEGRATION_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_INTEGRATION_FAILURE,
      payload: error,
    };
  }
};

export default updateIntegration;
