import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { DateTime } from 'luxon';

import { useMediaQuery } from 'helpers';
import { InternetConnection, Navbar, Sidebar, ActionsBar } from '_components/_shared';

import classNames from 'classnames';
import { ContainerScroller } from './styles';

export default function AuthorizedLayout({
  children,
  showFooter,
  showFooterMobile,
  fullHeight,
}) {
  const { isMobile } = useMediaQuery();

  const isInSettingsPage = window.location.pathname.includes('/configuracoes');

  const renderFooter = useCallback(() => {
    if (!showFooter) {
      return null;
    }

    if (isMobile && !showFooterMobile) {
      return null;
    }

    if (isInSettingsPage) {
      return null;
    }

    if (isMobile) {
      return (
        <footer
          style={{
            paddingTop: '10px',
            paddingBottom: '50px',
          }}
        >
          <div className="text-center justify-content-center align-items-center">
            <div>
              <p className="text-center justify-content-center align-items-center">
                Você está em um ambiente seguro.
                <p>Todos os seus dados estão protegidos.</p>
              </p>
              <p>
                &copy; {DateTime.now().year}
                &nbsp;Zenply Software Ltda - Todos os direitos reservados
              </p>
            </div>
          </div>
        </footer>
      );
    }

    return (
      <footer className="pt-5 text-muted">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p>
              &copy; {DateTime.now().year}
              &nbsp;Zenply Software Ltda - Todos os direitos reservados
            </p>
          </div>
          <div>
            <p>Você está em um ambiente seguro. Todos os seus dados estão protegidos.</p>
          </div>
        </div>
      </footer>
    );
  }, [isMobile, showFooter, showFooterMobile, isInSettingsPage]);

  return (
    <ContainerScroller className="container-scroller d-flex flex-column min-vh-100">
      <Navbar />
      {!isMobile && <ActionsBar />}
      <Sidebar />
      <Container
        fluid
        className={classNames({
          'h-100': fullHeight,
          'flex-grow-1': true,
          'pr-0': isInSettingsPage,
        })}
      >
        <InternetConnection withPaddingTop />
        {children}
      </Container>
      {renderFooter()}
    </ContainerScroller>
  );
}

AuthorizedLayout.defaultProps = {
  children: null,
  fullHeight: false,
  showFooter: true,
  showFooterMobile: true,
};

AuthorizedLayout.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  showFooter: PropTypes.bool,
  showFooterMobile: PropTypes.bool,
};
